import React from "react";
import ResCarousel from "../../../Carousel/ResCarousel";
// import { carouselData } from "../../../../data/carousel/carouselData";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../mini-components/Loader";
import { getBChainStat } from "../../../../redux/actions/bChainStatAction";
import { getResearch } from "../../../../redux/actions/researchAction";
import CSStatistic from "../../../ComingSoon/CSStatistic";

class ToolStats extends React.Component {
  state = {
    icon: { id: null }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    const icon =
      nextProps.cryptos.length &&
      nextProps.cryptos.find(c => c.id === "CFIx" + cryptoId);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;

    if (cryptoId !== prevCryptoId) {
      this.props.getBChainStat(cryptoId);
      this.props.getResearch(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getBChainStat(cryptoId);
    this.props.getResearch(cryptoId);
  }
  render() {
    const { cryptoId } = this.props.match.params;
    const { icon } = this.state;
    const { researches, isLoading, 
      // researchLoading, 
      bChain } = this.props;

    if (cryptoId === "CFIx") {
      return (
        <div className="tools">
          <CSStatistic />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }
    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
            <div className="toolsHead__text">
              <div className="main">{icon.name}</div>
              <div className="sub">
                {researches.catagory} 
              </div>
            </div>
            <div className="toolsHead__title">statistic tool</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>

        {/* <div className="tools__card mb--sm">
        <p className="stat__chartHead">Transaction per Day</p>
        <CustomAreaChart className="stat__chartCont" classChart="stat__chart" />
      </div> */}

        {isLoading ? (
          <Loader />
        ) : (
          <div className="tools__card mb--sm">
            <p className="averageHead">Current Daily Averages 24 Hrs</p>
            <div className="stat__dailyCont">
            <div className="section">
               {bChain.average_size_usd && <div className="dailyInfo">
                  <p className="title">Average transaction value</p>
                  <div className="result">{bChain.average_size_usd}</div>
                </div>}
               {bChain.value_txs && <div className="dailyInfo">
                  <p className="title">Value of transactions</p>
                  <div className="result">{bChain.value_txs}</div>
                </div>}
               {bChain.fees_average && <div className="dailyInfo">
                  <p className="title">Average Fee</p>
                  <div className="result">{bChain.fees_average}</div>
                </div>}
            </div>
            <div className="section">
               {bChain.num_circulation && <div className="dailyInfo">
                  <p className="title">Assets in circulation</p>
                  <div className="result">{bChain.num_circulation}</div>
                </div>}
               {bChain.number_txs && <div className="dailyInfo">
                  <p className="title">Transactions</p>
                  <div className="result">{bChain.number_txs}</div>
                </div>}
               {bChain.RAMprice && <div className="dailyInfo">
                  <p className="title">RAM Price</p>
                  <div className="result">{bChain.RAMprice}</div>
                </div>}
            </div>
            </div>
          </div>
        )}

        <div className="tools__slide">
          <ResCarousel className="tools__slider"/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.bChain.isLoading,
  bChain: state.bChain.bChain,
  cryptos: state.navbarCryptos.cryptos,
  researches: state.researches.research,
  researchLoading: state.researches.isLoading
});

const mapDispatchToProps = dispatch => ({
  getBChainStat: id => dispatch(getBChainStat(id)),
  getResearch: cryptoId => dispatch(getResearch(cryptoId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolStats)
);
