import React, { Component } from "react";
import { Table } from "reactstrap";
import { NavBarCrypto, NavBar, Footer } from "../../Layout/Layout";
import MarketCapData from "./MarketCapData";
import { marketCap } from "../../../data/marketCap/marketCap";

export class MarketCap extends Component {
  render() {
    return (
      <>
        <NavBar title="Mfx - Market Cap" />
        <NavBarCrypto />
        <div className="page">
          <div className="marketCap">
            <Table responsive className="marketCap__table">
              <thead>
                <tr>
                  <th className="numb">No</th>
                  <th>Asset</th>
                  <th>Price</th>
                  <th>% Change</th>
                  <th>MFx</th>
                </tr>
              </thead>
              <tbody>
                {marketCap.map((cap, index) => (
                  <MarketCapData
                    key={index}
                    id={cap.id}
                    image={cap.image}
                    coin={cap.coin}
                    price={cap.price}
                    color={cap.status}
                    change={cap.change}
                    mfx={cap.mfx}
                    stripped={cap.stripped}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default MarketCap;
