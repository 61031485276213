export function getTwitterProps(str = "") {
  return {
    profile: str.split("/")[3],
    slug: str.split("/")[5]
  };
}

export const httpCheck = url => {
  if (!url || url === "NaN") {
    return "#";
  }
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    return "https://" + url;
  }
  return url;
};


export const getMonth = (month) => {
  switch(month) {
    case 0: return 'Jan';
    case 1: return 'Feb';
    case 2: return 'Mar';
    case 3: return 'Apr';
    case 4: return 'May';
    case 5: return 'Jun';
    case 6: return 'Jul';
    case 7: return 'Aug';
    case 8: return 'Sept';
    case 9: return 'Oct';
    case 10: return 'Nov';
    case 11: return 'Des';
    default: return 'Invalid Month'
  }
}

export const notNAN = (data) => {
  return !isNaN(data) && data
}