export const cardYoutube = [
  {
    video: "4l3bTDlT6ZI",
    title: "Cidro cover by Dyah Novia",
    channel: "Dengerin Musik Channel",
    time:"3 Days ago"
  },
  {
    video: "4l3bTDlT6ZI",
    title: "Pamer Bojo cover by Dyah Novia",
    channel: "Dengerin Musik Channel",
    time: "20 Minutes ago"
  },
  {
    video: "4l3bTDlT6ZI",
    title: "Bagai Langit dan Bumi cover by Dyah Novia",
    channel: "Dengerin Musik Channel",
    time: "9 Days ago"
  },
  {
    video: "4l3bTDlT6ZI",
    title: "Selalu Mengalah cover by Dyah Novia",
    channel: "Dengerin Musik Channel",
    time: "12 Years ago"
  },
  {
    video: "4l3bTDlT6ZI",
    title: "Sayang cover by Dyah Novia",
    channel: "Dengerin Musik Channel",
    time: "2 Seconds ago"
  },
  {
    video: "4l3bTDlT6ZI",
    title: "Tanjungmas Ninggal Janji by Dyah Novia",
    channel: "Dengerin Musik Channel",
    time: "1 Year ago"
  },
];
