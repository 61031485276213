export const getTutorial = () => dispatch => {
    const visited = localStorage.getItem("visited")

    if (visited) {
        return dispatch({ type: "CLOSE_PAGE"})
    }

    return dispatch({ type: "GO_TO_PAGE1"})
}

export const goToPage2 = () => dispatch => {
    dispatch({ type: "GO_TO_PAGE2"})
}

export const closePage = () => dispatch => {
    localStorage.setItem("visited", "true")
    dispatch({ type: "CLOSE_PAGE"})
}

