const initState = {
  isLoading: false,
  loadmoreLoading: false,
  error: null,
  youtubes: [],
  pageInfo: {},
  nextPageToken: null,
  id: null
};

const youtubeReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_YOUTUBES_LOADING":
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case "GET_YOUTUBES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        youtubes: action.payload.items,
        pageInfo: action.payload.pageInfo,
        nextPageToken: action.payload.nextPageToken,
        id: action.id
      };
    case "GET_YOUTUBES_ERROR":
      return {
        ...state,
        youtubes: [],
        error: action.error,
        isLoading: false
      };
    case "LOAD_MORE_YOUTUBES_LOADING":
      return {
        ...state,
        error: null,
        loadmoreLoading: true
      };
    case "LOAD_MORE_YOUTUBES_SUCCESS":
      return {
        ...state,
        loadmoreLoading: false,
        youtubes: [...state.youtubes, ...action.payload.items],
        pageInfo: action.payload.pageInfo,
        nextPageToken: action.payload.nextPageToken,
        id: action.id
      };
    case "LOAD_MORE_YOUTUBES_ERROR":
      return {
        ...state,
        youtubes: [],
        error: action.error,
        loadmoreLoading: false
      };
    case "NO_YOUTUBE_FOUND":
      return {
        ...state,
        youtubes: [],
        error: { error: "No youtube found"},
        loadmoreLoading: false,
        isLoading: false
      };
    default:
      return state;
  }
};

export default youtubeReducer;
