import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import AnalysisYoutube from "../MediaPage/MediaSocial/AnalysisYoutube";
import MediaTwitter from "../MediaPage/MediaSocial/MediaTwitter";
// import MediaInstagram from "../MediaPage/MediaSocial/MediaInstagram";
import AnalysisPageGuide from "./AnalysisPageGuide";
import { connect } from "react-redux";

class AnalysisPageSocial extends Component {
  render() {
    const { params } = this.props.match;
    return (
      <div className="analysisPage__social">
        {/** this.props.mounted **/ false && <AnalysisPageGuide title="Social Tool" />}
        <div className="analysisPage__head">
          <div className="vLine" />
          <div className="text">
            <p className="main">official</p>
            <p className="sub">social feeds</p>
          </div>
        </div>

        <div className="social__cont">
          <div className="mediaHighlightSocial__btns">
            <NavLink to={`/explorer/${params.tool}/${params.cryptoId}/youtube`}>
              <div
                className={`youtube ${params.socmed === "youtube" && "active"}`}
              >
                <i className="fab fa-youtube" />
              </div>
            </NavLink>
            <NavLink to={`/explorer/${params.tool}/${params.cryptoId}/twitter`}>
              <div
                className={`twitter ${params.socmed === "twitter" && "active"}`}
              >
                <i className="fab fa-twitter" />
              </div>
            </NavLink>
          </div>
          <div className="social__media">
            {params.socmed === "youtube" ? (
              <AnalysisYoutube />
            ) : (
              <MediaTwitter />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mounted: state.tutorial.mounted
});

export default withRouter(connect(mapStateToProps, null)(AnalysisPageSocial));
