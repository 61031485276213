import React from "react";
import ResCarousel from "../../Carousel/ResCarousel";
import MediaHeadNewsCard from "./MediaHeadNewsCard";
import { carouselData } from "../../../data/carousel/carouselData";
import { cardHeadNews } from "../../../data/mediaPage/cardHeadNews";

const MediaHeadNews = () => {
  return (
    <>
      <div className="mediaHeadNews mediaHeadNews--slide mediaHeadNews__mobile">
        <ResCarousel className="mediaHeadNews__slider" data={carouselData} />
      </div>
      <MediaHeadNewsCard
        bgImg="http://bit.ly/2mu61Kt"
        coinImg={require("../../../assets/img/navBarCrypto/btc.png")}
        newsHead="EXCLUSIVE: First interview with Craig Wright after judge orders him
        to pay $5 billion in Bitcoin"
        overlay="cardx"
        news="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
          quidem tenetur sunt cupiditate dicta, ab fugit explicabo excepturi
          corporis, suscipit dolore voluptate aspernatur, molestiae laborum
          quaerat expedita? Iste, at ratione! Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
          quidem tenetur sunt cupiditate dicta, ab fugit explicabo excepturi
          corporis, suscipit dolore voluptate aspernatur, molestiae laborum
          quaerat expedita? Iste, at ratione!"
        link="#"
      />
      <div className="mediaHeadNews mediaHeadNews--slide mediaHeadNews__desktop">
        <ResCarousel className="mediaHeadNews__slider" data={carouselData} />
      </div>
      {cardHeadNews.map((card, index) => (
        <MediaHeadNewsCard
          key={index}
          bgImg={card.bgImg}
          coinImg={card.coinImg}
          newsHead={card.newsHead}
          overlay={card.overlay}
          news={card.news}
          link={card.link}
        />
      ))}
    </>
  );
};

export default MediaHeadNews;
