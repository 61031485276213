import db from "../../config/firestore";
let unsubscribeResearch;

export const getResearch = cryptoId => dispatch => {
  dispatch({ type: "GET_RESEARCH_LOADING" });
  unsubscribeResearch && unsubscribeResearch();
  unsubscribeResearch = db
    .collection("intellDB")
    .doc('CFIx'+cryptoId.toUpperCase())
    .onSnapshot(
      snapshots => {
        if (snapshots.exists) {
          dispatch({
            type: "GET_RESEARCH_SUCCESS",
            payload: snapshots.data()
          });
        } else {
          dispatch({ type: "GET_RESEARCH_ERROR", error: "not found." });
        }
      },
      error => dispatch({ type: "GET_RESEARCH_ERROR", error })
    );
};
