import React from "react";

const ToolExperienceTrans = () => {
  return (
    <>
      <div className="text">
        <div className="expSummary__head">
          <div className="expSummary__info">
            <p className="title">Date</p>
            <p className="result result--orange">Fri Oct 04 2019</p>
          </div>
          <div className="expSummary__info">
            <p className="title">Hash</p>
            <p className="result result--final result--hash">
              3219832019321u21321j312312o3n12j3n12j3n1
            </p>
          </div>
        </div>
        <div className="expSummary__trans expSummary__trans--trans">
          <div className="hLine" />

          <div className="tools__card exp__addressCard">
            <div className="exp__tableCont">
              <div className="input">
                <p className="inputHead">input</p>
                <div className="exp__tableRec">
                  <p className="rec">recipient</p>
                  <p className="result">38daw2j3njnk3012</p>
                </div>
              </div>

              <div className="output">
                <p className="outputHead">output</p>
                <div className="exp__tableRec">
                  <p className="rec">recipient</p>
                  <p className="result">dwadwadwadwa32321313</p>
                </div>
              </div>

              <div className="exp__tableValue">
                <div className="title">value</div>
                <div className="result">5440</div>
              </div>
              <div className="exp__tableValue">
                <div className="title">value</div>
                <div className="result">5440</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolExperienceTrans;
