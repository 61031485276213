import React from "react";
import { Row, Col, UncontrolledCollapse } from "reactstrap";
import { connect } from "react-redux";
import { getNavbarCryptos } from "../../../redux/actions/navbarCryptoAction";
import { withRouter, NavLink } from "react-router-dom";

import { CustomButton } from "../../Form/Form";
import NavBarCoin from "./NavBarCoin";
import NavBarCoinDesk from "./NavBarCoinDesk";
import Loader from "../../mini-components/Loader";
import AnalysisPageGuide from "../../Pages/AnalysisPage/AnalysisPageGuide";
import { getTutorial, goToPage2 } from "../../../redux/actions/tutorialAction";

class NavBarCrypto extends React.Component {
  componentDidMount() {
    this.props.getNavbarCryptos();
    this.props.getTutorial()
  }
  render() {
    const { navbarCryptos, isLoading, match, mounted, goToPage2 } = this.props;
    const params = match.params;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {/* <Loader /> */}
        <div className="navBarCrypto">
         {mounted == "PAGE1" && <AnalysisPageGuide
            type="navbarCrypto"
            title="The Crypto Flow Index Control Panel"
            func={() => goToPage2()}
          />}
          <div className="navBarCrypto__mobile">
            <Row>
              <NavLink to={`/explorer/${params.tool}/CFIx/${params.socmed}`}>
                <Col xs="6" className="navBarCrypto__lead">
                  <img
                    src={require("../../../assets/img/navBarCrypto/cfi.png")}
                    alt="cfn"
                  />
                  <div className="navBarCrypto__leadtext">
                    <p className="main fw--bold">
                      CFx
                    <img
                        src={require(`../../../assets/img/navBarCrypto/${navbarCryptos.length && navbarCryptos.find(each => each.id === "CFIxCFIx").pcp > 0 ? 'icon-up-green.png' : 'icon-dn-red.png'}`)}
                        alt="arrow"
                      />
                    </p>
                    <p className="price">
                      {navbarCryptos.length && navbarCryptos.find(each => each.id === "CFIxCFIx").close.toFixed(2)}
                    </p>
                  </div>
                </Col>
              </NavLink>
              <Col xs="6" className="navBarCrypto__btncont">
                <CustomButton
                  btnColor="orange"
                  btnText="See Crypto"
                  btnIcon="none"
                  className="navBarCrypto__btn"
                  id="toggler"
                />
              </Col>
            </Row>
            <div className="navBarCrypto__collapse">
              <UncontrolledCollapse toggler="#toggler">
                <div className="navBarCrypto__coins">
                  <Row>
                    {navbarCryptos &&
                      navbarCryptos
                        .filter(crypto => crypto.order !== undefined)
                        .map(coin => (
                          <NavBarCoin
                            name={coin.id.replace("CFIx", "CFx")}
                            close={coin.close}
                            status={coin.pcp > 0 && "up"}
                            img={coin.iconURL}
                            key={coin.id}
                            id={coin.id}
                          />
                        ))}
                  </Row>
                </div>
              </UncontrolledCollapse>
            </div>
          </div>
          <NavBarCoinDesk cryptos={navbarCryptos} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    mounted: state.tutorial.mounted,
    navbarCryptos: state.navbarCryptos.cryptos,
    isLoading: state.navbarCryptos.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTutorial: () => dispatch(getTutorial()),
    goToPage2: () => dispatch(goToPage2()),
    getNavbarCryptos: () => dispatch(getNavbarCryptos())
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavBarCrypto)
);
