import React from "react";
import { Button } from "reactstrap";

const CustomInput = ({ btnText, btnColor, className, btnIcon, id }) => {
  return (
    <Button id={id} className={`btn fw--bold ${className} btn--${btnColor}`}>
      {
          btnIcon==="none" ? null : <i className={`${btnIcon} btn__icon`}/>
      }
      {btnText}
    </Button>
  );
};

export default CustomInput;
