const initState = {
    start: true,
    isLoading: false,
    error: null,
    btcAddress: {},
  };
  
  const btcAddress = (state = initState, action) => {
    switch (action.type) {
      case "GET_BTC_ADDRESS_LOADING":
        return {
          ...state,
          error: null,
          isLoading: true,
          start: false
        };
      case "GET_BTC_ADDRESS_SUCCESS":
        return {
          ...state,
          isLoading: false,
          btcAddress: action.payload,
          start: false
        };
      case "GET_BTC_ADDRESS_ERROR":
        return {
          ...state,
          btcAddress: {},
          error: action.error,
          isLoading: false,
          start: true
        };
      default:
        return state;
    }
  };
  
  export default btcAddress;
  