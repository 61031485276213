import db from "../../config/firestore";
import axios from "axios";
let unsubscribeTwitter;
let unsubscribeYoutube;

export const getTwitters = cryptoId => dispatch => {
  dispatch({ type: "GET_TWITTERS_LOADING" });
  let collection = `CFIx${cryptoId.toUpperCase()}`
  if (cryptoId === 'CFIx') {
    collection = 'CFIxCFIx'
  }
  unsubscribeTwitter && unsubscribeTwitter();
  unsubscribeTwitter = db
    .collection("B_chain_master")
    .doc(collection)
    .onSnapshot(
      snapshots => {
        if (!snapshots.exists) {
          return dispatch({ type: "GET_TWITTERS_ERROR", error: {error: "NOT FOUND."}})
        }
        dispatch({
          type: "GET_TWITTERS_SUCCESS",
          payload: snapshots.data().official_twitter
        });
      },
      error => dispatch({ type: "GET_TWITTERS_ERROR", error })
    );
};

export const getYoutubes = (cryptoId, nextPageToken = null) => dispatch => {
  dispatch({ type: "GET_YOUTUBES_LOADING" });
  let collection = `CFIx${cryptoId.toUpperCase()}`
  if (cryptoId === 'CFIx') {
    collection = 'CFIxCFIx'
  }
  unsubscribeYoutube && unsubscribeYoutube();
  unsubscribeYoutube = db
    .collection("B_chain_master")
    .doc(collection)
    .onSnapshot(
      snapshots => {
        if (!snapshots.exists) {
          return dispatch({ type: "NO_YOUTUBE_FOUND"})
        }
        const youtube = snapshots.data().official_youtube //{ ...snapshots.data() };
        const youtubeUrl = youtube || "";
        const id = youtubeUrl.split("playlist?list=")[1];
        if (!id) {
          return dispatch({ type: "NO_YOUTUBE_FOUND"})
        }
        const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=3&${
          nextPageToken ? `pageToken=${nextPageToken}` : ""
        }&playlistId=${id}&key=AIzaSyDJStYN3XsOy61DAq6NqDQAMLljaRZaY0A`;

        axios
          .get(url)
          .then(({ data }) => {
            dispatch({ type: "GET_YOUTUBES_SUCCESS", payload: data, id });
          })
          .catch(error => {
            dispatch({ type: "GET_YOUTUBES_ERROR", error });
          });
      },
      error => dispatch({ type: "GET_YOUTUBES_ERROR", error })
    );
};

export const loadMoreYoutube = (id, nextPageToken) => dispatch => {
  dispatch({ type: "LOAD_MORE_YOUTUBES_LOADING" });
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=3&${
    nextPageToken ? `pageToken=${nextPageToken}` : ""
  }&playlistId=${id}&key=AIzaSyDJStYN3XsOy61DAq6NqDQAMLljaRZaY0A`;
  axios
    .get(url)
    .then(({ data }) => {
      dispatch({ type: "LOAD_MORE_YOUTUBES_SUCCESS", payload: data, id });
    })
    .catch(error => {
      dispatch({ type: "LOAD_MORE_YOUTUBES_ERROR", error });
    });
};
