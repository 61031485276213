const initState = {
  isLoading: false,
  error: null,
  twitters: "",
};

const twitterReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_TWITTERS_LOADING":
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case "GET_TWITTERS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        twitters: action.payload
      };
    case "GET_TWITTERS_ERROR":
      return {
        ...state,
        twitters: {},
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};

export default twitterReducer;
