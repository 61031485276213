import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyCSJF2SYKWGjkNaJFDCSpIcvnIVlnQDICE",
  authDomain: "cfix00001.firebaseapp.com",
  databaseURL: "https://cfix00001.firebaseio.com",
  projectId: "cfix00001",
  storageBucket: "cfix00001.appspot.com",
  messagingSenderId: "422506388715"
};

firebase.initializeApp(config);

export const storage = firebase.storage();
const db = firebase.firestore();

export default db;