import React from "react";
import PropTypes from "prop-types";

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import { BarSeries, CandlestickSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
  CrossHairCursor,
  PriceCoordinate
  // MouseCoordinateX,
  // MouseCoordinateY
} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { OHLCTooltip } from "react-stockcharts/lib/tooltip";
import { fitWidth } from "react-stockcharts/lib/helper";
import { last } from "react-stockcharts/lib/utils";
import { HoverTooltip } from "react-stockcharts/lib/tooltip";
import { ema } from "react-stockcharts/lib/indicator";

const numberFormat = format(".2f");
const dateFormat = timeFormat("%Y-%m-%d");

function tooltipContent(ys) {
  return ({ currentItem, xAccessor }) => {
    return {
      x: dateFormat(xAccessor(currentItem)),
      y: [
        {
          label: "open",
          value: currentItem.open && numberFormat(currentItem.open)
        },
        {
          label: "high",
          value: currentItem.high && numberFormat(currentItem.high)
        },
        {
          label: "low",
          value: currentItem.low && numberFormat(currentItem.low)
        },
        {
          label: "close",
          value: currentItem.close && numberFormat(currentItem.close)
        }
      ]
        .concat(
          ys.map(each => ({
            label: each.label,
            value: each.value(currentItem),
            stroke: each.stroke
          }))
        )
        .filter(line => line.value)
    };
  };
}

class CandleStickChartWithCHMousePointer extends React.Component {
  render() {
    const { type, data: initialData, width, ratio } = this.props;

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      d => d.date
    );
    const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(
      initialData
    );

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 150)]);
    const xExtents = [start, end];

    // const ema20 = ema()
    //   .id(0)
    //   .options({ windowSize: 20 })
    //   .merge((d, c) => {
    //     d.ema20 = c;
    //   })
    //   .accessor(d => d.ema20);

    const ema50 = ema()
      .id(2)
      .options({ windowSize: 50 })
      .merge((d, c) => {
        d.ema50 = c;
      })
      .accessor(d => d.ema50);

    return (
      <>
        <ChartCanvas
          height={600}  
          width={width}
          mouseMoveEvent={true}
          panEvent={false}
          zoomEvent={false}
          clamp={false}
          ratio={ratio}
          margin={{ left: 70, right: 70, top: 30, bottom: 30 }}
          padding={20}
          type={type}
          seriesName="MSFT"
          data={data}
          xScale={xScale}
          xAccessor={xAccessor}
          displayXAccessor={displayXAccessor}
          xExtents={xExtents}
        >
          <Chart id={1} height={400} yExtents={[d => [d.high, d.low]]}>
            <XAxis axisAt="bottom" orient="bottom" showTicks={false} />
            <YAxis axisAt="right" orient="right" ticks={5} />

            <CandlestickSeries
              fill={d => (d.close > d.open ? "#2CD573" : "#ff4658")}
            />
            <OHLCTooltip forChart={1} origin={[-40, -20]} />
            <HoverTooltip
              yAccessor={ema50.accessor()}
              tooltipContent={tooltipContent([])}
              fontSize={15}
            />
            <PriceCoordinate
              at="left"
              orient="left"
              displayFormat={format(".2f")}
            />

            <PriceCoordinate
              at="right"
              orient="right"
              stroke="#3490DC"
              strokeWidth={1}
              fill="#FFFFFF"
              textFill="#22292F"
              arrowWidth={7}
              strokeDasharray="ShortDash"
              displayFormat={format(".2f")}
            />
          </Chart>

          <Chart
            id={2}
            height={150}
            yExtents={d => d.volume}
            origin={(w, h) => [0, h - 150]}
          >
            <XAxis axisAt="bottom" orient="bottom" />
            <YAxis
              axisAt="left"
              orient="left"
              ticks={5}
              tickFormat={format(".2s")}
            />

            {/* <MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")}
					/>
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".4s")}
					/> */}

            <BarSeries
              yAccessor={d => d.volume}
              fill={d => (d.close > d.open ? "#2CD573" : "#FF4658")}
            />
          </Chart>
          <CrossHairCursor />
        </ChartCanvas>
        {/*  */}

        {/* 
			<ChartCanvas 
				height={600}
				mouseMoveEvent={true}
				panEvent={false}
				zoomEvent={false}
				ratio={ratio}
				width={width}
				margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
				padding={20}
				type={type}
				seriesName="MSFT"
				data={data}
				xScale={xScale}
				xAccessor={xAccessor}
				displayXAccessor={displayXAccessor}
				xExtents={xExtents}
			>

				<Chart id={1} height={400} yExtents={d => [d.high, d.low]} >
					<YAxis axisAt="right" orient="right" ticks={5} />
					<XAxis axisAt="bottom" orient="bottom" showTicks={false}/>
					<CandlestickSeries />
				</Chart>
				<Chart id={2} origin={(w, h) => [0, h - 150]} height={150} yExtents={d => d.volume}>
					<XAxis axisAt="bottom" orient="bottom"/>
					<YAxis axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")}/>
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")}
					/>
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".4s")}
					/>
					<BarSeries yAccessor={d => d.volume} fill={(d) => d.close > d.open ? "#6BA583" : "red"} />
				</Chart>
			</ChartCanvas> */}
      </>
    );
  }
}

CandleStickChartWithCHMousePointer.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired
};

CandleStickChartWithCHMousePointer.defaultProps = {
  type: "svg"
};
CandleStickChartWithCHMousePointer = fitWidth(
  CandleStickChartWithCHMousePointer
);

export default CandleStickChartWithCHMousePointer;
