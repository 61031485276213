import React, { Component } from "react";
import MediaYoutube from "./MediaSocial/MediaYoutube";
import MediaTwitter from "./MediaSocial/MediaTwitter";
import MediaInstagram from "./MediaSocial/MediaInstagram";

class MediaHighlightSocial extends Component {
  state = {
    media: "youtube",
    activeYoutube: "active",
    activeTwitter: "inactive",
    activeInstagram: "inactive"
  };

  changeSocial(social) {
    if (social === "twitter") {
      this.setState({
        activeYoutube: "inactive",
        activeTwitter: "active",
        activeInstagram: "inactive",
        media: social
      });
    } else if (social === "instagram") {
      this.setState({
        activeYoutube: "inactive",
        activeTwitter: "inactive",
        activeInstagram: "active",
        media: social
      });
    } else if (social === "youtube") {
      this.setState({
        activeYoutube: "active",
        activeTwitter: "inactive",
        activeInstagram: "inactive",
        media: social
      });
    }
  }

  render() {
    const { media, activeYoutube, activeTwitter, activeInstagram } = this.state;
    return (
      <div className="mediaHighlightSocial">
        <div className="mediaHighlight__head">
          <div className="vLine"></div>
          <div className="text">
            <div className="main">curated social</div>
            <div className="sub">Analyst's Top Social</div>
          </div>
        </div>

        <div className="mediaHighlight__content">
          <div className="mediaHighlightSocial__btns">
            <div
              className={`youtube ${activeYoutube}`}
              onClick={() => this.changeSocial("youtube")}
            >
              <i className="fab fa-youtube" />
            </div>
            <div
              className={`twitter ${activeTwitter}`}
              onClick={() => this.changeSocial("twitter")}
            >
              <i className="fab fa-twitter" />
            </div>
            <div
              className={`instagram ${activeInstagram}`}
              onClick={() => this.changeSocial("instagram")}
            >
              <i className="fab fa-instagram" />
            </div>
          </div>
          <div className="mediaHighlightSocial__content">
            {media === "youtube" ? (
              <MediaYoutube />
            ) : media === "twitter" ? (
              <MediaTwitter />
            ) : (
              <MediaInstagram />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MediaHighlightSocial;
