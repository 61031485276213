import React from "react";
import { cardYoutube } from "../../../../data/mediaPage/cardYoutube";

const MediaYoutube = () => {
  return (
    <div className="mediaHighlightSocial__card--youtubeCont">
      {cardYoutube.map((video, index) => (
        <div className="mediaHighlightSocial__card--youtube" key={index}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="video embed-responsive-item"
              src={`https://www.youtube.com/embed/${video.video}`}
              allowFullScreen
              title="video"
            ></iframe>
          </div>
          <div className="text">
            <p className="main">{video.title}</p>
            <div className="creator">
              <i class="fab fa-youtube"></i> {video.channel}
            </div>
            <div className="time">
              <i class="fas fa-clock"></i> {video.time}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MediaYoutube;
