import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import db, { storage } from "../../config/firestore";

class ResCarousel extends React.Component {
  state = {
    slides: []
  };

  getSlides = () => {
    db.collection("CFN_intelligence")
      .orderBy("position_number")
      .onSnapshot(snapshot => {
        const slides = [];
        const promises = [];
        snapshot.forEach(data => {
          const slide = data.data();
          const action = storage.refFromURL(slide.imageURL).getDownloadURL();
          promises.push(action);
          slides.push({ ...slide, id: data.id });
        });
        Promise.all(promises).then(values => {
          for (let i = 0; i < promises.length; i++) {
            slides[i].imageURL = values[i];
          }
          this.setState({ slides });
        });
      });
  };
  componentDidMount() {
    this.getSlides()
  }
  render() {
    const { slides } = this.state
    const { className } = this.props;
    return (
      <Carousel
        // emulateTouch
        // infiniteLoop
        showThumbs={false}
        showStatus={false}
        // autoPlay
        interval={3000}
        className={className}
      >
        {
          slides.map(carousel => (
            <div key={carousel.id}>
              <img src={carousel.imageURL} alt="slideImg" />
            </div>
          ))}
      </Carousel>
    );
  }
}

export default ResCarousel;
