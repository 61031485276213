import React from 'react'

const Lost = () => {
    return (
        <div>
            <h1>You're Lost, it is not the page you are looking for</h1>
        </div>
    )
}

export default Lost
