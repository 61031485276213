import React from "react";
import { NavLink } from "react-router-dom";

const icons = [
  {
    img: require("../../../assets/img/pageAnalysis/white-research.png"),
    alt: "research-icon",
    text: "Research",
    url: "research"
  },
  {
    img: require("../../../assets/img/pageAnalysis/white-blockgen.png"),
    alt: "blockgen-icon",
    url: "genesis",
    text: "Block Genesis"
  },
  {
    img: require("../../../assets/img/pageAnalysis/white-dev.png"),
    alt: "dev-icon",
    url: "dev",
    text: "Development"
  },

  {
    img: require("../../../assets/img/pageAnalysis/white-blockex.png"),
    alt: "explorer-icon",
    text: "Explorer",
    url: "block-explorer"
  },
  {
    img: require("../../../assets/img/pageAnalysis/white-stats.png"),
    alt: "stats-icon",
    text: "Statistic",
    url: "stats"
  },
  {
    img: require("../../../assets/img/pageAnalysis/white-event.png"),
    alt: "event-icon",
    text: "Live Event",
    url: "event"
  },
  {
    img: require("../../../assets/img/pageAnalysis/white-chart.png"),
    alt: "chart-icon",
    text: "Chart",
    url: "chart"
  },
  {
    img: "",
    alt: "",
    text: ""
  }
];

const AnalysisPageGuide = props => (
  <div
    className={`guide ${
      props.type === "content"
        ? "content"
        : props.type === "navbarCrypto"
        ? "navbarCrypto"
        : null
    }`}
  >
    {props.type === "content" ? (
      <>
        <div className={`guide__tip ${props.tool}`} />
        <h1 className="guide__title">{props.title}</h1>

        <div className="guide__icons">
          {icons.map((icon, i) => (
            <NavLink key={i} style={{color: "white"}} to={`/explorer/${icon.url}/${props.cryptoId}/${props.socmed}`} className="guide__icon">
            
              <img src={icon.img} alt={icon.alt} />
              <p>{icon.text}</p>
            
            </NavLink>
          ))}
        </div>

        <h1 className="guide__title end">
          Enjoy the control Panel !!
          <img
            src="http://www.vhv.rs/file/max/29/296247_smiling-emoji-png.png"
            alt="smile"
          />
        </h1>
        <button className="guide__btn content" onClick={() => props.func()}>
          close
        </button>
      </>
    ) : props.type === "navbarCrypto" ? (
      <>
        <div className="guide__tip" />
        <h1 className="guide__title">
          Welcome to <br /> {props.title}
        </h1>
        <div className="guide__tag">To begin select a crypto index above</div>
        <button onClick={() => props.func()} className="guide__btn">
          Next
        </button>
      </>
    ) : (
      <>
        <div className="guide__tip" />
        <h1 className="guide__title">{props.title}</h1>
      </>
    )}
  </div>
);

export default AnalysisPageGuide;
