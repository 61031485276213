import db from "../../config/firestore";
let unsubscribeArticle;
let unsubscribeLoadMore = [];

export const getArticles = cryptoId => dispatch => {
  dispatch({ type: "GET_ARTICLES_LOADING" });
  let collection = `kw_article_CFIx${cryptoId.toUpperCase()}`;
  if (cryptoId === "CFIx") {
    collection = "kw_article_CFIxCFIx";
  }
  unsubscribeLoadMore.length && unsubscribeLoadMore.forEach(load => load());
  unsubscribeArticle && unsubscribeArticle();
  unsubscribeArticle = db
    .collection(collection)
    .orderBy("upload_date", "desc")
    .limit(5)
    .onSnapshot(
      snapshots => {
        const articles = [];
        snapshots.forEach(snapshot => {
          articles.push({ id: snapshot.id, ...snapshot.data() });
        });
        dispatch({ type: "GET_ARTICLES_SUCCESS", payload: articles });
      },
      error => dispatch({ type: "GET_ARTICLES_ERROR", error })
    );
};

export const loadMoreArticles = (cryptoId, lastArticle) => async dispatch => {
  dispatch({ type: "LOAD_MORE_ARTICLES_LOADING" });
  let collection = `kw_article_CFIx${cryptoId.toUpperCase()}`;
  if (cryptoId === "CFIx") {
    collection = "kw_article_CFIxCFIx";
  }
  const startAfter = await db
    .collection(collection)
    .doc(lastArticle.id)
    .get();
  const unsubscribe = db
    .collection(collection)
    .limit(5)
    .orderBy("upload_date", "desc")
    .startAfter(startAfter)
    .onSnapshot(
      snapshots => {
        const articles = [];
        snapshots.forEach(snapshot => {
          articles.push({
            id: snapshot.id,
            ...snapshot.data()
          });
        });
        unsubscribeLoadMore.push(unsubscribe);
        dispatch({ type: "LOAD_MORE_ARTICLES_SUCCESS", payload: articles });
      },
      error => dispatch({ type: "LOAD_MORE_ARTICLES_ERROR", error })
    );
};
