import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Provider } from 'react-redux'

import store from '../../redux/store'

import { SignIn, MediaPageLayout, AnalysisPageLayout, MarketCap } from "../Pages/Pages";
import Lost from "../Pages/Lost/Lost";


const App = () => {
  return (
    <Provider store={store} >
      <Router>
        <Route exact path="/" component={() => <Redirect to="/explorer/chart/CFIx/twitter" />} />
        <Route exact path="/signin" component={SignIn} />
        <Route path="/media" component={MediaPageLayout} />
        <Route path="/explorer/:tool/:cryptoId/:socmed" component={AnalysisPageLayout} />
        <Route path="/marketcap" component={MarketCap} />
        <Route path="/lost" component={Lost} />
      </Router>
    </Provider>
  );
};

export default App;
