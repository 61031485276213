import React from "react";

const MediaNewsCard = ({ img, title, date, author, news, coin, className }) => {
  return (
    <div className={`mediaHighlightNews__card ${className}`}>
      <div className="head" style={{ backgroundImage: `url(${img})` }}>
        <p className="main">{title}</p>
        <div className="hLine"></div>
      </div>
      <div className="text">
        <div className="infos">
          <div className="info">
            <i className="fas fa-clock"></i> {date}
          </div>
          <div className="info">
            <i className="fas fa-user"></i> by {author}
          </div>
        </div>
        <p className="news">{news}</p>
        {coin === "none" ? null : (
          <img src={coin} alt="coin" className="coin" />
        )}
      </div>
    </div>
  );
};

export default MediaNewsCard;
