import React from "react";

const MediaHeadNewsCard = ({
  bgImg,
  coinImg,
  newsHead,
  overlay,
  news,
  link,
}) => {
  return (
    <div className="mediaHeadNews">
      <div
        className="mediaHeadNewsCard__news"
        style={{
          backgroundImage: `url("${bgImg}")`
        }}
      >
        <div className="mediaHeadNewsCard__content">
          {coinImg === "none" ? <div /> : <img src={coinImg} alt="coinImg" />}
          <p className="news">{newsHead}</p>
        </div>
        <input type="checkbox" id={overlay} className="mediaHeadNewsCard__check" />
        <a href={link} className="mediaHeadNewsCard__link">
          Full Story
        </a>
        <div className="mediaHeadNewsCard__overlay">
          <p className="desc">{news}</p>
        </div>
      </div>
      <label for={overlay} className="mediaHeadNewsCard__label" />
    </div>
  );
};

export default MediaHeadNewsCard;
