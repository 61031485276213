import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const CustomInput = ({ label, type, name, id, placeholder, required, icon }) => {
  return (
    <FormGroup className="customInput__group">
      {label === "none" ? null : <Label for={id}>{label}</Label>}
      <Input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        required={required}
        className="customInput__field"
      />
      <i className={`${icon} customInput__icon`}/>
    </FormGroup>
  );
};

export default CustomInput;
