import React from "react";

const comingImg = {
  width: "100%",
  borderRadius: "15px"
};

const CSDev = () => {
  return (
    <div style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}>
      <img
        style={comingImg}
        src={require("../../assets/comingsoon/devTeam.png")}
        alt="comingSoonDevTeam"
      />
    </div>
  );
};

export default CSDev;
