export const marketCap = [
    {
        id: 1,
        image: "http://bit.ly/2JmZp9k",
        coin: "Bitcoin",
        price: 8645,
        change: 7.66,
        status: "green",
        mfx: "155,635,390,365",
        stripped: false,
    },
    {
        id: 2,
        image: "http://bit.ly/2Nci88v",
        coin: "Ethereum",
        price: 187.25,
        change: 7.14,
        status: "red",
        mfx: "20,265,287,604",
        stripped: true,
    },
    {
        id: 3,
        image: "http://bit.ly/2PkiTyQ",
        coin: "XRP",
        price: 0.306,
        change: 4.95,
        status: "green",
        mfx: "13,245,224,715",
        stripped: false,
    },
    {
        id: 3,
        image: "http://bit.ly/2PkiTyQ",
        coin: "XRP",
        price: 0.306,
        change: 4.95,
        status: "green",
        mfx: "13,245,224,715",
        stripped: false,
    },
    {
        id: 3,
        image: "http://bit.ly/2PkiTyQ",
        coin: "XRP",
        price: 0.306,
        change: 4.95,
        status: "green",
        mfx: "13,245,224,715",
        stripped: false,
    },
    {
        id: 3,
        image: "http://bit.ly/2PkiTyQ",
        coin: "XRP",
        price: 0.306,
        change: 4.95,
        status: "green",
        mfx: "13,245,224,715",
        stripped: false,
    },
    {
        id: 3,
        image: "http://bit.ly/2PkiTyQ",
        coin: "XRP",
        price: 0.306,
        change: 4.95,
        status: "green",
        mfx: "13,245,224,715",
        stripped: false,
    },
    {
        id: 3,
        image: "http://bit.ly/2PkiTyQ",
        coin: "XRP",
        price: 0.306,
        change: 4.95,
        status: "green",
        mfx: "13,245,224,715",
        stripped: false,
    },
]