import React from 'react';
import InstagramEmbed from 'react-instagram-embed';

const MediaInstagram = () => {
    return (
<InstagramEmbed
  url='https://instagr.am/p/B2EQ5C-ARbr/'
//   maxWidth={320}
  hideCaption={false}
  containerTagName='div'
  injectScript
  protocol=''
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
    )
}

export default MediaInstagram