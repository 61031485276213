import React from "react";
import { Col } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";

const NavBarCoin = ({ name, close, status, img, match, id }) => {
  const { params } = match;
  const twoDecimals = ["eth", "btc", "bch"];
  return (
    <Col xs="3" sm="2" md="3">
    <div className={`navBarCrypto__coin ${params.cryptoId === id.substring(4) && 'active'}`}>
    <NavLink
      to={`/explorer/${params.tool}/${id.substring(4)}/${params.socmed}`}
    >
          <p className="name fw--bold">{name}</p>
          <p className="price fw--bold">
            <span className={status === "up" ? "text--green" : "text--red"}>
              $
            </span>
            &nbsp;
            {twoDecimals.includes(id.substring(4).toLowerCase())
              ? close.toFixed(2)
              : close.toFixed(3)}
          </p>
          <img src={img} alt="coinLogo" />
        
    </NavLink>
    </div>
      </Col>
  );
};

export default withRouter(NavBarCoin);
