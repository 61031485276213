import React from "react";
import MediaNewsCard from './MediaNewsCard';
import { cardHNews } from "../../../data/mediaPage/cardHighlightNews";

const MediaHighlightNews = () => {
  return (
    <div className="mediaHighlightNews">

      <div className="mediaHighlight__head">
      <div className="vLine"></div>
        <div className="text">
          <div className="main">news river</div>
          <div className="sub">Hand Curated</div>
        </div>
      </div>
      
      <div className="mediaHighlight__content">
        <div className="mediaHighlightNews__content">
          {cardHNews.map((news, index) => (
            <MediaNewsCard
              key={index}
              img={news.img}
              title={news.title}
              date={news.date}
              author={news.author}
              news={news.news}
              coin={news.coin}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediaHighlightNews;
