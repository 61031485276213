import React from "react";
// import CustomCandleChart from "../../../Charts/CandleStickChart/CustomCandleChart";
import ResCarousel from "../../../Carousel/ResCarousel";
import { carouselData } from "../../../../data/carousel/carouselData";
import { TypeChooser } from "react-stockcharts/lib/helper";
import Chart from "../../../Charts/ChartTool/Chart";
import { getCryptoChart } from "../../../../redux/actions/ChartAction";
import { getResearch } from "../../../../redux/actions/researchAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../mini-components/Loader";

class ToolChart extends React.Component {
  state = {
    icon: { id: null }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    const icon =
      nextProps.cryptos.length &&
      nextProps.cryptos.find(c => c.id === "CFIx" + cryptoId);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;

    if (cryptoId !== prevCryptoId) {
      this.props.getCryptoChart(cryptoId);
      this.props.getResearch(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getCryptoChart(cryptoId);
    this.props.getResearch(cryptoId);
  }

  render() {
    const { icon } = this.state;
    const { chart, isLoading, researches } = this.props;
    // const { cryptoId } = this.props.match.params;

    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
            <div className="toolsHead__text">
              <div className="main">{icon.name}</div>
              <div className="sub">
                {researches.catagory}
              </div>
            </div>
            <div className="toolsHead__title">chart tool</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>

        <div className="tools__card mb--sm">
          <div className="chart__chart">
            {isLoading ? (
              <Loader />
            ) : (
              <TypeChooser>
                {type => <Chart type={type} data={chart} />}
              </TypeChooser>
            )}
          </div>
        </div>

        <div className="tools__slide">
          <ResCarousel className="tools__slider" data={carouselData} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cryptos: state.navbarCryptos.cryptos,
  researches: state.researches.research,
  researchLoading: state.researches.isLoading,
  chart: state.chart.cryptoChart,
  isLoading: state.chart.isLoading
});

const mapDispatchToProps = dispatch => ({
  getResearch: cryptoId => dispatch(getResearch(cryptoId)),
  getCryptoChart: cryptoId => dispatch(getCryptoChart(cryptoId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolChart)
);
