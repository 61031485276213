export const cardHeadNews = [
  {
    bgImg: "http://bit.ly/2m5cZp2",
    coinImg: require("../../assets/img/navBarCrypto/btc.png"),
    newsHead: "Get up. Get down! Our new media app is ready to rock!!",
    overlay: "card1",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  },
  {
    bgImg: "http://bit.ly/2koLBSa",
    coinImg: "none",
    newsHead: "CME Group to launch bitcoin options in Q1 2020",
    overlay: "card2",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  },
  {
    bgImg: "https://on.mktw.net/2kxN74G",
    coinImg: "none",
    newsHead: "CME Group to launch bitcoin options in Q1 2020",
    overlay: "card3",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  },
  {
    bgImg: "https://on.mktw.net/2kxN74G",
    coinImg: "none",
    newsHead: "CME Group to launch bitcoin options in Q1 2020",
    overlay: "card4",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  },
  {
    bgImg: "https://on.mktw.net/2kxN74G",
    coinImg: "none",
    newsHead: "CME Group to launch bitcoin options in Q1 2020",
    overlay: "card5",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  },
  {
    bgImg: "https://on.mktw.net/2kxN74G",
    coinImg: "none",
    newsHead: "CME Group to launch bitcoin options in Q1 2020",
    overlay: "card6",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  },
  {
    bgImg: "https://on.mktw.net/2kxN74G",
    coinImg: "none",
    newsHead: "CME Group to launch bitcoin options in Q1 2020",
    overlay: "card7",
    link: "#",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam facilis exercitationem incidunt, reiciendis nihil accusamus recusandae veritatis hic ea minima, consequatur sed cumque quidem omnis minus, deserunt impedit facere."
  }
];
