import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getTwitters } from "../../../../redux/actions/socialMediaAction";
import Loader from "../.././../mini-components/Loader";
import CSYoutube from "../../../ComingSoon/CSYoutube";

class MediaTwitter extends React.Component {
  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;
    if (cryptoId !== prevCryptoId) {
      this.props.getTwitters(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getTwitters(cryptoId);
  }

  render() {
    const { twitters, isLoading } = this.props;

    if (isLoading) {
      return <Loader />
    }

    if (!isLoading && !twitters.length) {
      return <CSYoutube />;
    }

    return <TwitterTimelineEmbed sourceType="URL" url={twitters} placeholder={<h1>LOADING HOEY</h1>} />;
  }
}

const mapStateToProps = state => {
  return {
    twitters: state.twitters.twitters,
    isLoading: state.twitters.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTwitters: id => dispatch(getTwitters(id))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MediaTwitter)
);
