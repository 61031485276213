import db from "../../config/firestore";

let unsubscribeBchainStat;
export const getBChainStat = cryptoId => dispatch => {
  dispatch({ type: "GET_BCHAINSTAT_LOADING" });
  unsubscribeBchainStat && unsubscribeBchainStat();
  unsubscribeBchainStat = db
    // .collection("B_chain_stats")
    .collection("B_chain_master")
    .doc("CFIx" + cryptoId)
    .onSnapshot(
      snapshot => {
        if (snapshot.exists) {
          const bChainStat = snapshot.data().statistic //["2019-08-02"];
          dispatch({ type: "GET_BCHAINSTAT_SUCCESS", payload: bChainStat });
        } else {
          dispatch({ type: "GET_BCHAINSTAT_ERROR", error: {error: 'not found.'} })
        }
      },
      error => dispatch({ type: "GET_BCHAINSTAT_ERROR", error })
    );
};
