export const SIGNIN = "/";
export const SIGNUP = "/signup";

export const MEDIA = "/media";
export const ANALYSIS = "/explorer";
export const ANALYSIS_TWITTER = "/explorer/twitter";
export const ANALYSIS_IG = "/explorer/ig";
export const ANALYSIS_DEV = "/explorer/dev";
export const ANALYSIS_STATS = "/explorer/stats";
export const ANALYSIS_RESEARCH = "/explorer/research";
export const ANALYSIS_GENESIS = "/explorer/genesis";
export const ANALYSIS_EXP = "/explorer/experience";
export const ANALYSIS_EVENT = "/explorer/event";
export const MARKET_CAP = "/market"
