const initState = {
  isLoading: false,
  loadMoreLoading: false,
  articles: [],
  error: null
};

const article = (state = initState, action) => {
  switch (action.type) {
    case "GET_ARTICLES_LOADING":
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case "GET_ARTICLES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        articles: action.payload
      };
    case "GET_ARTICLES_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case "LOAD_MORE_ARTICLES_LOADING":
      return {
        ...state,
        loadMoreLoading: true,
        error: null
      };
    case "LOAD_MORE_ARTICLES_SUCCESS":
      return {
        ...state,
        loadMoreLoading: false,
        error: null,
        articles: [...state.articles, ...action.payload]
      };
    case "LOAD_MORE_ARTICLES_ERROR":
      return {
        ...state,
        loadMoreLoading: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default article;
