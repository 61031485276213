import React from "react";
import { NavLink, Route, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  ToolChart,
  ToolDev,
  ToolEvent,
  ToolExperience,
  ToolGenesis,
  ToolResearch,
  ToolStats
} from "./Tools/Tools";
import AnalysisPageGuide from "./AnalysisPageGuide";

import { connect } from "react-redux";
import { closePage } from "../../../redux/actions/tutorialAction";

class AnalysisPageContent extends React.Component {

 render() {
  const { match, mounted, closePage } = this.props
  const { cryptoId, socmed, tool } = match.params;
  return (
    <div className="analysisPage__content">
      {mounted == "PAGE2" && <AnalysisPageGuide
        type="content"
        title="Now apply a tool in the main control panel"
        func={closePage}
        tool={tool}
        socmed={socmed}
        cryptoId={cryptoId}
      />}
      <div className="analysisPage__nav">
        <NavLink to={`/explorer/research/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Research Tool">
            {tool === "research" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-research@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
        <div className="divider"></div>
        <NavLink to={`/explorer/dev/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Development Tool">
            {tool === "dev" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-dev@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
        <div className="divider"></div>
        <NavLink to={`/explorer/stats/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Statistic Tool">
            {tool === "stats" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-stats@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
        <div className="divider"></div>
        <NavLink to={`/explorer/chart/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Chart Tool">
            {tool === "chart" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-chart@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
        <div className="divider"></div>
        <NavLink to={`/explorer/genesis/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Genesis Tool">
            {tool === "genesis" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-blockgen@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
        <div className="divider"></div>
        <NavLink to={`/explorer/block-explorer/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Explorer Tool">
            {tool === "block-explorer" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-blockexp@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
        <div className="divider"></div>
        <NavLink to={`/explorer/event/${cryptoId}/${socmed}`}>
          <div className="analysisPage__navIcon" data-tip="Event Tool">
            {tool === "event" && <div className="active" />}
            <ReactTooltip />
            <img
              src={require("../../../assets/img/pageAnalysis/icon-event@3x.png")}
              alt="navIcon"
            />
          </div>
        </NavLink>
      </div>
      <div className="analysisPage__tools">
        <Route
          path="/explorer/research/:cryptoId/:socmed"
          component={ToolResearch}
        />
        <Route path="/explorer/dev/:cryptoId/:socmed" component={ToolDev} />
        <Route path="/explorer/stats/:cryptoId/:socmed" component={ToolStats} />
        <Route
          exact
          path="/explorer/chart/:cryptoId/:socmed"
          component={ToolChart}
        />
        <Route
          exact
          path="/explorer/genesis/:cryptoId/:socmed"
          component={ToolGenesis}
        />
        <Route
          exact
          path="/explorer/block-explorer/:cryptoId/:socmed"
          component={ToolExperience}
        />
        <Route
          exact
          path="/explorer/event/:cryptoId/:socmed"
          component={ToolEvent}
        />
      </div>
    </div>
  );
 }
};

const mapStateToProps = state => ({
  mounted: state.tutorial.mounted
})

const mapDispatchToProps = dispatch => ({
  closePage: () => dispatch(closePage())
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisPageContent)
);
