export const cardHNews = [
  {
    img: "http://bit.ly/356OnxU",
    title: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
    date: "August 29th 2019",
    author: "Decrypt",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aperiam iste sint quidem eius. Numquam, impedit cumque! Consequuntur quisquam quo accusantium numquam iste voluptate modi, optio in, architecto aliquid totam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aperiam iste sint quidem eius. Numquam, impedit cumque! Consequuntur quisquam quo accusantium numquam iste voluptate modi, optio in, architecto aliquid totam!",
    coin: "http://bit.ly/2o4NxRX"
  },
  {
    img: "http://bit.ly/2oei4N1",
    title: "Alias aperiam iste sint quidem eius. Numquam, impedit cumque!",
    date: "September 29th 2019",
    author: "Ayam",
    news:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aperiam iste sint quidem eius. Numquam, impedit cumque! Consequuntur quisquam quo accusantium numquam iste voluptate modi, optio in, architecto aliquid totam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aperiam iste sint quidem eius. Numquam, impedit cumque! Consequuntur quisquam quo accusantium numquam iste voluptate modi, optio in, architecto aliquid totam!",
    coin: "http://bit.ly/357h79W"
  }
];
