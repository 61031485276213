import axios from "axios";

export const getBtcAddress = () => dispatch => {
    const value = "Ma2DrB78K7jmAwaomqZNRMCvgQrNjE2QC"
  dispatch({ type: "GET_BTC_ADDRESS_LOADING" });

  axios({
    method: "GET",
    url: `https://blockchain.info/rawaddr/${value}?cors=true&limit=5`
  })
    .then(({ data }) => {
      const explorer = { ...data };
      const txs = [];
      data.txs.forEach(async datum => {
        // datum.confirmations = datum.block_height
        //   ? this.state.blockCount - datum.block_height + 1
        //   : "unconfirmed";
        txs.push(datum);
      });
      explorer.txs = txs;
      console.log({ explorer })
      dispatch({ type: "GET_BTC_ADDRESS_SUCCESS", payload: explorer });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: "GET_BTC_ADDRESS_ERROR", error });
    });
};
