import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import articleReducer from './reducers/articleReducer'
import navbarCryptos from './reducers/navbarCryptoReducer'
import twitterReducer from './reducers/twitterReducer'
import youtubeReducer from './reducers/youtubeReducer'
import researchesReducer from './reducers/researchReducer'
import developerReducer from './reducers/develperReducer'
import commitReducer from './reducers/githubCommitReducer'
import bchainStatReducer from "./reducers/bchainStatReducer";
import chartReducer from "./reducers/cryptoChartReducer";
import btcAddressReducer from "./reducers/explorerBitcoinAddressReducer";
import tutorialReducer from "./reducers/tutorialReducer"

const store = createStore(
    combineReducers({
        articles: articleReducer,
        navbarCryptos: navbarCryptos,
        twitters: twitterReducer,
        youtubes: youtubeReducer,
        researches: researchesReducer,
        developers: developerReducer,
        commit: commitReducer,
        bChain: bchainStatReducer,
        chart: chartReducer,
        btcAddress:btcAddressReducer,
        tutorial: tutorialReducer
    }),
    compose(
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
)

export default store