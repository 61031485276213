import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import ResCarousel from "../../../Carousel/ResCarousel";
import { getResearch } from "../../../../redux/actions/researchAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../mini-components/Loader";
import CSLiveEvent from "../../../ComingSoon/CSLiveEvent";

class ToolEvent extends React.Component {
  state = {
    icon: { id: null }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    const icon =
      nextProps.cryptos.length &&
      nextProps.cryptos.find(c => c.id === "CFIx" + cryptoId);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;

    if (cryptoId !== prevCryptoId) {
      this.props.getResearch(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getResearch(cryptoId);
  }

  render() {
    const { cryptoId } = this.props.match.params;
    const { icon } = this.state;
    const { researches, researchLoading } = this.props;

    if (cryptoId === "CFIx") {
      return (
        <div className="tools">
          <CSLiveEvent />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }
    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            {researchLoading ? (
              <Loader />
            ) : (
              <>
                <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
                <div className="toolsHead__text">
                  <div className="main">{icon.name}</div>
                  <div className="sub">
                    {researches.catagory}
                  </div>
                </div>
              </>
            )}
            <div className="toolsHead__title">live events</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>

        <div className="tools__card mb--sm">
          <div className="event__twitter">
            <TwitterTimelineEmbed
              key={icon.liveEvent}
              sourceType="profile"
              screenName={icon.liveEvent}
              placeholder={<Loader />}
            />
          </div>
        </div>

        {/* <div className="tools__card mb--sm">
          <p className="researchSummary__head">HACKATHON</p>
          <p className="researchSummary__desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
            unde error, quos in accusantium, laudantium quia, fuga quaerat
            pariatur quo quibusdam cupiditate commodi veritatis doloribus
            tempore iste atque obcaecati aperiam.
          </p>
        </div> */}

        {/* <div className="tools__card mb--sm">
          <p className="researchSummary__head">CONFERENCES</p>
          <p className="researchSummary__desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
            unde error, quos in accusantium, laudantium quia, fuga quaerat
            pariatur quo quibusdam cupiditate commodi veritatis doloribus
            tempore iste atque obcaecati aperiam.
          </p>
        </div> */}

        <div className="tools__slide">
          <ResCarousel className="tools__slider" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cryptos: state.navbarCryptos.cryptos,
  researches: state.researches.research,
  researchLoading: state.researches.isLoading
});

const mapDispatchToProps = dispatch => ({
  getResearch: cryptoId => dispatch(getResearch(cryptoId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolEvent)
);
