import React from "react";
import { NavLink, withRouter } from "react-router-dom";

const NavBarCoinDesk = ({ match, cryptos }) => {
  const params = match.params;

  if (!cryptos.length) {
    return null;
  }
  const filteredCryptos = cryptos.filter(crypto => crypto.order !== undefined && crypto.id !== "CFIxCFIx");
  return (
    <div className="navBarCrypto__desktop">
      <div className="navBarCrypto__cont">
        {filteredCryptos.slice(0, 8).map((crypto, i) => (
          <React.Fragment key={crypto.id}>
            <NavLink
              to={`/explorer/${params.tool}/${crypto.id.substring(4)}/${
                params.socmed
              }`}
            >
              <div
                className={`navBarCrypto__coindesktop ${params.cryptoId ===
                  crypto.id.substring(4) && "active"}`}
              >
                <p className="name fw--bold">
                  {crypto.id.replace("CFIx", "CFx")}
                </p>
                <p className="price fw--bold">
                  <span
                    className={crypto.pcp > 0 ? "text--green" : "text--red"}
                  >
                    $
                  </span>
                  &nbsp;
                  {crypto.id === "CFIxETH"
                    ? crypto.close.toFixed(2)
                    : crypto.close.toFixed(3)}
                </p>
                <img src={crypto.iconURL} alt="coin" />
              </div>
            </NavLink>
            {i !== 7 && (
              <div className="navBarCrypto__divider">
                <div className="navBarCrypto__line" />
              </div>
            )}
          </React.Fragment>
        ))}

        <div className="navBarCrypto__leaddesk">
          <NavLink to={`/explorer/${params.tool}/CFIx/${params.socmed}`}>
            <div
              className={`navBarCrypto__leaddeskcoin ${params.cryptoId ===
                "CFIx" && "active"}`}
            >
              <img
                src={require("../../../assets/img/navBarCrypto/cfi.png")}
                alt="cfi"
              />
              <div className="navBarCrypto__leaddesktext">
                <p className="name fw--bold">
                  CFx
                  <img
                    src={require(`../../../assets/img/navBarCrypto/${
                      cryptos.find(c => c.id === "CFIxCFIx").pcp > 0
                        ? "icon-up-green.png"
                        : "icon-up-green.png"
                    }`)}
                    alt="arrow"
                  />
                </p>
                <p className="price fw--bold">
                  {cryptos.find(c => c.id === "CFIxCFIx").close.toFixed(2)}
                </p>
              </div>
            </div>
          </NavLink>
        </div>
        {filteredCryptos.slice(8, 16).map((crypto, i) => (
          <React.Fragment key={crypto.id}>
            <NavLink
              to={`/explorer/${params.tool}/${crypto.id.substring(4)}/${
                params.socmed
              }`}
            >
              <div
                className={`navBarCrypto__coindesktop ${params.cryptoId ===
                  crypto.id.substring(4) && "active"}`}
              >
                <p className="name fw--bold">{crypto.id}</p>
                <p className="price fw--bold">
                  <span
                    className={crypto.pcp > 0 ? "text--green" : "text--red"}
                  >
                    $
                  </span>
                  &nbsp;
                  {crypto.id === "CFIxBTC" || crypto.id === "CFIxBCH"
                    ? crypto.close.toFixed(2)
                    : crypto.close.toFixed(3)}
                </p>
                <img src={crypto.iconURL} alt="coin" />
              </div>
            </NavLink>

            {i !== 7 && (
              <div className="navBarCrypto__divider">
                <div className="navBarCrypto__line" />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default withRouter(NavBarCoinDesk);
