const initState = {
  mounted: null
};

const tutorialReducer = (state = initState, action) => {
  switch (action.type) {
    case "GO_TO_PAGE1":
      return {
        ...state,
        mounted: "PAGE1"
      };
    case "GO_TO_PAGE2":
      return {
        ...state,
        mounted: "PAGE2"
      };
    case "CLOSE_PAGE":
      return {
        ...state,
        mounted: null
      };

    default:
      return state;
  }
};

export default tutorialReducer;
