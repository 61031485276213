import React from "react";
import MediaHeadNews from "./MediaHeadNews";
import MediaHighlightSocial from "./MediaHighlightSocial";
import MediaHighlightNews from "./MediaHighlightNews";
import MediaNews from "./MediaNews";
import { NavBarCrypto, NavBar, Footer } from "../../Layout/Layout";

const MediaPageLayout = () => {
  return (
    <>
      <NavBar title="AI Assisted | Hand Currated"/>
      <NavBarCrypto />
      <div className="page">
        <MediaHeadNews />
        <div className="mediaPage__highlight">
          <MediaHighlightSocial />
          <MediaHighlightNews />
        </div>
        <div className="mediaPage__news">
          <MediaNews />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MediaPageLayout;
