import React from "react";
import AnalysisPageContent from "./AnalysisPageContent";
import AnalysisPageNews from "./AnalysisPageNews";
import AnalysisPageSocial from "./AnalysisPageSocial";
import { NavBarCrypto, NavBar, Footer } from "../../Layout/Layout";
import ResCarousel from "../../Carousel/ResCarousel";
import { withRouter, Redirect } from "react-router-dom";

class AnalysisPageLayout extends React.Component {
  render() {
    const { params } = this.props.match;
    const cryptos = [
      "qtum",
      "etc",
      "neo",
      "iota",
      "ada",
      "trx",
      "eos",
      "eth",
      "cfix",
      "btc",
      "xrp",
      "bch",
      "ltc",
      "xlm",
      "xmr",
      "dash",
      "zec"
    ];
    const tools = [
      "research",
      "dev",
      "stats",
      "chart",
      "genesis",
      "block-explorer",
      "event"
    ];
    if (params.socmed !== "youtube" && params.socmed !== "twitter") {
      return <Redirect to="/lost" />;
    }

    if (!cryptos.includes(params.cryptoId.toLowerCase())) {
      return <Redirect to="/lost" />;
    }

    if (!tools.includes(params.tool.toLowerCase())) {
      return <Redirect to="/lost" />;
    }
    return (
      <>
        <NavBar title="Knowledge In Hand | Value You Keep" />
        <NavBarCrypto />
        <div className="page page--analysis">
          <AnalysisPageContent />
          <AnalysisPageNews />
          <AnalysisPageSocial />
          <div className="analysisPage__slide">
            <ResCarousel className="analysisPage__slider" />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default withRouter(AnalysisPageLayout);
