import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => {
  return (
    <div className="loader">
        <Spinner />
    </div>
  );
};

export default Loader;
