import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const Footer = () => {
  const footerLinkIcon = {
    fontSize: "2em",
    cursor: "pointer",
    transition: "all .5s",
    color: "white",
    marginRight: "2.3rem"
  };
  return (
    <div className="footer">
      <Row>
        <Col xs="12" sm="3" md="4" xl="3">
          <img
            src={require("../../../assets/img/footer/cfi-logo-dark@3x.png")}
            alt="cfiLogo"
            className="footer__logo"
          />
        </Col>
        <Col xs="6" sm="2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://medium.com/@cryptoflowintel/crypto-flow-intelligence-v0-01-1b404cca9b02"
          >
            <p className="footer__link">intelligence</p>
          </a>
        </Col>
        <Col xs="6" sm="2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://media.keymajorcoin.com"
          >
            <p className="footer__link">media</p>
          </a>
        </Col>
        <Col xs="6" sm="2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://controlpanel.keymajorcoin.com"
          >
            <p className="footer__link">Control Panel</p>
          </a>
        </Col>
        <Col xs="6" sm="2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://marketcap.keymajorcoin.com"
          >
            <p className="footer__link">Market Cap</p>
          </a>
        </Col>

        <Col xs="6" xl="2">
          <Link to="#">
            <p className="footer__info">Terms and Privacy Policy</p>
          </Link>
        </Col>
        <Col xs="12" xl="5" className="footer__infoDesktop">
          <Link to="#">
            <p className="footer__info">
              Copyright ©2019 Crypto Flow Intel. All Rights Reserved.
            </p>
          </Link>
        </Col>
        <Col xs="6" xl="2">
          {/* <Link to="#">
            <p className="footer__info">Contact Us</p>
          </Link> */}
        </Col>
        <Col xs="12" xl="5" className="footer__infoMobile">
          <Link to="#">
            <p className="footer__info">
              Copyright ©2019 Crypto Flow Intel. All Rights Reserved.
            </p>
          </Link>
        </Col>
        <Col xs="12" xl="3">
          <div className="footer__socialbtns">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/KMC_intelReport"
            >
              <i style={footerLinkIcon} className="fab fa-telegram-plane" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/KeyMajorCoin"
            >
              <i style={footerLinkIcon} className="fab fa-twitter"></i>
            </a>

            {/* <i className="fab fa-instagram"></i> */}
            <div className="footer__line"></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
