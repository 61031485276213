import React from 'react';

const comingImg = {
  width: '100%',
  borderRadius: '15px'
};

const CSExplorer = () => {
  return (
    <div style={{marginBottom:'1.5rem', marginTop: '1.5rem' }}>
      <img
        style={comingImg}
        src={require('../../assets/comingsoon/explorer.png')}
        alt="comingSoonExplorer"
      />
    </div>
  );
};

export default CSExplorer;
