import React from "react";
import ResCarousel from "../../../Carousel/ResCarousel";
// import { carouselData } from "../../../../data/carousel/carouselData";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getResearch } from "../../../../redux/actions/researchAction";
import Loader from "../../../mini-components/Loader";
import CSResearch from "../../../ComingSoon/CSResearch";
import { httpCheck } from "../../../../utils/index";
class ToolResearch extends React.Component {
  state = {
    icon: { id: null }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    const icon =
      nextProps.cryptos.length &&
      nextProps.cryptos.find(c => c.id === "CFIx" + cryptoId);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;
    if (cryptoId !== prevCryptoId) {
      this.props.getResearch(cryptoId);
    }
  }
  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getResearch(cryptoId);
  }
  render() {
    const { icon } = this.state;
    const { researches, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="tools">
          <Loader />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }

    if (!researches.summary) {
      return (
        <div className="tools">
          <CSResearch />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }

    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
            <div className="toolsHead__text">
              <div className="main">{icon.name}</div>
              <div className="sub">
                {researches.catagory}
              </div>
            </div>
            <div className="toolsHead__title">research tool</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>
        <div className="tools__card mb--sm mb--sm--lastCard">
          <div className="researchSummary__cont">
            <p className="researchSummary__head">summary</p>
            <p className="researchSummary__desc">{researches.summary}</p>
          </div>
          <div className="researchSummary__cont">
            <p className="researchSummary__head">incentives</p>
            <p className="researchSummary__desc">{researches.incentives}</p>
          </div>
          <div className="researchSummary__cont">
            <p className="researchSummary__head">links</p>
            <div className="researchSummary__links">
              {researches.official_wallet && (
                <div className="researchSummary__link">
                  <img src={icon.iconURL} alt="linkImg" className="coinImg" />
                  <a
                    href={httpCheck(researches.official_wallet)}
                    target="_blank"
                    className="link"
                    rel="noopener noreferrer"
                  >
                    Official Wallet
                  </a>
                </div>
              )}
              {researches.wiki && (
                <div className="researchSummary__link">
                  <img
                    src={icon.iconURL}
                    alt="linkImg"
                    className="coinImg"
                  />
                  <a href={httpCheck(researches.wiki)} target="_blank" rel="noopener noreferrer" className="link">
                    Wiki
                  </a>
                </div>
              )}
              {researches.medium && (
                <div className="researchSummary__link">
                  <img
                    src="http://bit.ly/2MHKNlE"
                    alt="linkImg"
                    className="coinImg"
                  />
                  <a href={httpCheck(researches.medium)} target="_blank" rel="noopener noreferrer" className="link">
                    Medium
                  </a>
                </div>
              )}
              {researches.website && (
                <div className="researchSummary__link">
                  <img src={icon.iconURL} alt="linkImg" className="coinImg" />
                  <a href={httpCheck(researches.website)} target="_blank" rel="noopener noreferrer" className="link">
                    Website
                  </a>
                </div>
              )}
              {researches.github && (
                <div className="researchSummary__link">
                  <img
                    src="http://bit.ly/33sY9J8"
                    alt="linkImg"
                    className="coinImg"
                  />
                  <a href={httpCheck(researches.github)} target="_blank" rel="noopener noreferrer" className="link">
                    Github
                  </a>
                </div>
              )}
              {researches.reddit && (
                <div className="researchSummary__link">
                  <img
                    src="http://bit.ly/2Bd5TTz"
                    alt="linkImg"
                    className="coinImg"
                  />
                  <a href={httpCheck(researches.reddit)} target="_blank" rel="noopener noreferrer" className="link">
                    Reddit
                  </a>
                </div>
              )}
              {researches.node_website && (
                <div className="researchSummary__link">
                  <img src={icon.iconURL} alt="linkImg" className="coinImg" />
                  <a
                    href={httpCheck(researches.node_website)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Full Node
                  </a>
                </div>
              )}
              {researches.twitter && (
                <div className="researchSummary__link">
                  <img
                    src="http://bit.ly/32dVnax"
                    alt="linkImg"
                    className="coinImg"
                  />
                  <a href={httpCheck(researches.twitter)} target="_blank" rel="noopener noreferrer" className="link">
                    Twitter
                  </a>
                </div>
              )}
              {researches.youtube && (
                <div className="researchSummary__link">
                  <img
                    src="http://bit.ly/32hiTDr"
                    alt="linkImg"
                    className="coinImg"
                  />
                  <a href={httpCheck(researches.youtube)} target="_blank" rel="noopener noreferrer" className="link">
                    Youtube
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="tools__slide">
          <ResCarousel className="tools__slider" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    researches: state.researches.research,
    isLoading: state.researches.isLoading,
    cryptos: state.navbarCryptos.cryptos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getResearch: cryptoId => dispatch(getResearch(cryptoId))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolResearch)
);
