import React from "react";

const ToolExperienceAddress = () => {
  return (
    <>
      <div className="text">
        <div className="expSummary__head">
          <div className="expSummary__info">
            <p className="title">Address</p>
            <p className="result">839372faldnkdl23123210nln0</p>
          </div>
          <div className="expSummary__info">
            <p className="title">Final Balance</p>
            <p className="result result--final">3000</p>
          </div>
        </div>
        <div className="expSummary__trans">
          <p className="transHead">transaction history</p>
          <div className="hLine" />
          <div className="expSummary__info">
            <p className="title">Hash</p>
            <p className="result">839372fald312dsadankdl23123210nln0</p>
          </div>
          <div className="expSummary__info">
            <p className="title">Time Stamp</p>
            <p className="result">Fri Oct 04 2019</p>
          </div>

          <div className="tools__card exp__addressCard">
            <div className="exp__tableCont">
              <div className="input">
                <p className="inputHead">input</p>
                <div className="exp__tableRec">
                  <p className="rec">recipient</p>
                  <p className="result">38daw2j3njnk3012</p>
                </div>
              </div>

              <div className="output">
                <p className="outputHead">output</p>
                <div className="exp__tableRec">
                  <p className="rec">recipient</p>
                  <p className="result">gsefs342dadaw23213</p>
                </div>
                <div className="exp__tableRec">
                  <p className="rec">recipient</p>
                  <p className="result">ccwaw43213eaddaw31</p>
                </div>
                <div className="exp__tableRec">
                  <p className="rec">recipient</p>
                  <p className="result">dwadwadwadwa32321313</p>
                </div>
              </div>

              <div className="exp__tableValue">
                <div className="title">value</div>
                <div className="result">5440</div>
              </div>
              <div className="exp__tableValue">
                <div className="title">value</div>
                <div className="result">5440</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolExperienceAddress;
