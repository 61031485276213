import db from "../../config/firestore";
import axios from "axios";

export const getDevelopers = cryptoId => async dispatch => {
  dispatch({ type: "GET_DEVELOPERS_LOADING" });
  try {
    const urls = await db
      .collection("CFN_icons")
      .where("symbol", "==", "CFIx" + cryptoId)
      .limit(1)
      .get();
    let url = {};
    urls.forEach(data => {
      url = { id: data.id, ...data.data() };
    });
    const repo = url.repo.split(".com/")[1];

    const { data: developers } = await axios({
      method: "GET",
      url: `https://api.github.com/repos/${repo}/contributors`,
      headers: {
        "User-Agent": "request",
        Authorization: `token 7cfdbb3ce3341f16256a643a0782fc70e9a062b3`
      }
    });
    // const res = [];
    const githubs = developers.splice(0, 6).map(async dev => {
      const { data: developer } = await axios({
        method: "GET",
        url: `https://api.github.com/user/${dev.id}`,
        headers: {
          "User-Agent": "request",
          Authorization: `token ab4d4f62bae0163683d99b0b7c040b129f1e55ff`
        }
      });
      dev.name = developer.name;
      return dev
    });
    Promise.all(githubs).then(c => dispatch({ type: "GET_DEVELOPERS_SUCCESS", payload: c }))
  } catch (error) {
    dispatch({ type: "GET_DEVELOPERS_ERROR", error });
  }
};
