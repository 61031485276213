export const cardNews = [
  {
    img: "http://bit.ly/2LPOAhB",
    title:
      "iron or wooden bar running along yard of ship to which sails fastened",
    date: "August 29th 2019",
    author: "Decrypt",
    news:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem architecto quia possimus ipsam minima ea, magnam consequuntur accusantium,tempore maxime accusamus sint, unde reiciendis modi praesentium laboriosa maiores repellendus odio.",
    coin: "none"
  },
  {
    img: "http://bit.ly/31VkyyE",
    title: "ship's chart indicating compass deflection due to ship's iron",
    date: "September 05th 2019",
    author: "Decrypt",
    news:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem architecto quia possimus ipsam minima ea, magnam consequuntur accusantium,tempore maxime accusamus sint, unde reiciendis modi praesentium laboriosa maiores repellendus odio.",
    coin: "http://bit.ly/30Q6gxC"
  },
  {
    img: "http://bit.ly/31VkyyE",
    title: "ship's chart indicating compass deflection due to ship's iron",
    date: "September 05th 2019",
    author: "Decrypt",
    news:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem architecto quia possimus ipsam minima ea, magnam consequuntur accusantium,tempore maxime accusamus sint, unde reiciendis modi praesentium laboriosa maiores repellendus odio.",
    coin: "http://bit.ly/30Q6gxC"
  },
  {
    img: "http://bit.ly/31VkyyE",
    title: "ship's chart indicating compass deflection due to ship's iron",
    date: "September 05th 2019",
    author: "Decrypt",
    news:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem architecto quia possimus ipsam minima ea, magnam consequuntur accusantium,tempore maxime accusamus sint, unde reiciendis modi praesentium laboriosa maiores repellendus odio.",
    coin: "http://bit.ly/30Q6gxC"
  }
];
