import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getArticles,
  loadMoreArticles
} from "../../../redux/actions/articleAction";
import Loader from "../../mini-components/Loader";
import AnalysisPageGuide from "./AnalysisPageGuide";

class AnalysisPageNews extends React.Component {
  state = {
    story: {
      clicked: false,
      id: null
    }
  };
  componentDidUpdate(prevProps, propsState) {
    const { match } = this.props;
    const cryptoId = match.params.cryptoId;

    if (cryptoId !== prevProps.match.params.cryptoId) {
      this.props.getArticles(cryptoId);
    }
  }
  componentDidMount() {
    const { match } = this.props;
    const cryptoId = match.params.cryptoId;
    this.props.getArticles(cryptoId);
  }
  render() {
    const { story } = this.state;
    const { match, articles, loadMoreLoading, mounted } = this.props;
    const cryptoId = match.params.cryptoId;
    return (
      <div className="analysisPage__news">
        {false && <AnalysisPageGuide title="News Tool" />}
        <div className="analysisPage__head">
          <div className="vLine" />
          <div className="text">
            <p className="main">the news</p>
            <p className="sub">
              {this.props.navbarCryptos.length
                ? cryptoId === "CFIx"
                  ? this.props.navbarCryptos.find(
                      each => each.id === "CFIxCFIx"
                    ).name
                  : this.props.navbarCryptos.find(
                      each => each.id === `CFIx${cryptoId.toUpperCase()}`
                    ).name
                : null}
            </p>
          </div>
        </div>

        {this.props.isLoading ? (
          <Loader />
        ) : (
          <div className="news__cont">
            {articles.map(article => (
              <div
                className="news-item"
                key={article.title}
                onClick={() => {
                  this.setState({
                    story: {
                      clicked: !this.state.story.clicked,
                      id: article.title
                    }
                  });
                }}
              >
                <div
                  className="news__card"
                  style={{ backgroundImage: `url(${article.image_name})` }}
                >
                  <div
                    className={`news__overlay ${story.id === article.title &&
                      story.clicked &&
                      "active"}`}
                  >
                    <p>{article.summary_nltk}</p>
                    <div className="link">
                      <a
                        href={article.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Full Story
                      </a>
                    </div>
                  </div>
                  <p className="title">{article.title}</p>
                </div>
              </div>
            ))}
            {loadMoreLoading ? (
              <Loader />
            ) : (
              <button
                className="btn btn--black analysisPage__btn"
                type="button"
                onClick={() =>
                  this.props.loadMoreArticles(
                    cryptoId,
                    articles[articles.length - 1]
                  )
                }
              >
                Load More
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getArticles: cryptoId => dispatch(getArticles(cryptoId)),
    loadMoreArticles: (id, lastArticle) =>
      dispatch(loadMoreArticles(id, lastArticle))
  };
};

const mapStateToProps = state => {
  return {
    articles: state.articles.articles,
    isLoading: state.articles.isLoading,
    error: state.articles.error,
    navbarCryptos: state.navbarCryptos.cryptos,
    navbarLoading: state.navbarCryptos.isLoading,
    loadMoreLoading: state.articles.loadMoreLoading,
    mounted: state.tutorial.mounted
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisPageNews)
);
