export const carouselData = [
  {
    img: "http://bit.ly/2mu7PmJ"
  },
  {
    img: "http://bit.ly/2m68SJ0"
  },
  {
    img: "http://bit.ly/2m5cBH6"
  }
];
