import React, { Component } from "react";
import ToolExperienceAddress from "./ToolExperienceAddress";
import ToolExperienceTrans from "./ToolExperienceTrans";

import { getBtcAddress } from "../../../../redux/actions/explorerAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getResearch } from "../../../../redux/actions/researchAction";
import Loader from "../../../mini-components/Loader";
import ResCarousel from "../../../Carousel/ResCarousel";

import CSExplorer from "../../../ComingSoon/CSExplorer";
import CSIntelligence from "../../../ComingSoon/CSIntelligence";

class ToolExperience extends Component {
  state = {
    comp: "address"
  };

  changeComp = e => {
    this.setState({ comp: e.target.value });
  };

  state = {
    icon: { id: null }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    const icon =
      nextProps.cryptos.length &&
      nextProps.cryptos.find(c => c.id === "CFIx" + cryptoId);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;

    if (cryptoId !== prevCryptoId) {
      this.props.getResearch(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getResearch(cryptoId);
    // this.props.getBtcAddress();
  }

  render() {
    const { cryptoId } = this.props.match.params;
    const { comp, icon } = this.state;
    const { researches, researchLoading } = this.props;

    if (cryptoId === "CFIx") {
      return (
        <div className="tools">
          <CSExplorer />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }

    if (cryptoId !== "CFIx") {
      return (
        <div className="tools">
          <CSIntelligence />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }

    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
            <div className="toolsHead__text">
              <div className="main">{icon.name}</div>
              <div className="sub">
                {researches.catagory} {icon.symbol}
              </div>
            </div>
            <div className="toolsHead__title">explorer tool</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>

        <div className="tools__card mb--sm">
          <div className="exp__searchGroup">
            <select
              name="expSelect"
              id="expSelect"
              className="exp__select"
              onChange={this.changeComp}
            >
              <option value="address">Address</option>
              <option value="transaction">Transaction</option>
            </select>
            <i className="fas fa-chevron-down exp__icon exp__icon--select" />
          </div>
        </div>

        <div className="tools__card mb--sm">
          <div className="exp__searchGroup">
            <i className="fas fa-search exp__icon exp__icon--search" />
            <input
              type="text"
              placeholder="Search Address or Transaction"
              className="exp__search"
            />
          </div>
        </div>

        {researchLoading ? (
          <Loader />
        ) : (
          <div className="tools__card mb--sm exp__card">
            <p className="head">summary</p>
            {comp === "address" ? (
              <ToolExperienceAddress />
            ) : (
              <ToolExperienceTrans />
            )}
          </div>
        )}

        <div className="tools__slide">
          <ResCarousel className="tools__slider" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cryptos: state.navbarCryptos.cryptos,
  researches: state.researches.research,
  researchLoading: state.researches.isLoading,
  isLoading: state.btcAddress.isLoading,
  btcAddress: state.btcAddress.btcAddress
});

const mapDispatchToProps = dispatch => ({
  getResearch: cryptoId => dispatch(getResearch(cryptoId)),
  getBtcAddress: () => dispatch(getBtcAddress())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolExperience)
);
