import React from "react";
import CustomAreaChart from "../../../Charts/AreaChart/CustomAreaChart";
import ResCarousel from "../../../Carousel/ResCarousel";
import { carouselData } from "../../../../data/carousel/carouselData";
import { getDevelopers } from "../../../../redux/actions/developerAction";
import { getResearch } from "../../../../redux/actions/researchAction";
import { getGithubCommit } from "../../../../redux/actions/ChartAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../mini-components/Loader";
import CSDevTeam from "../../../ComingSoon/CSDevTeam";

class ToolDev extends React.Component {
  state = {
    icon: { id: null }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    let crypto = "CFIx" + cryptoId.toUpperCase();
    if (cryptoId === "CFIx") {
      crypto = "CFIxCFIx";
    }
    const icon =
      nextProps.cryptos.length && nextProps.cryptos.find(c => c.id === crypto);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;

    if (cryptoId !== prevCryptoId) {
      this.props.getDevelopers(cryptoId);
      this.props.getResearch(cryptoId);
      this.props.getGithubCommit(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getDevelopers(cryptoId);
    this.props.getResearch(cryptoId);
    this.props.getGithubCommit(cryptoId);
  }
  render() {
    const { icon } = this.state;
    const {
      developers,
      isLoading,
      researches,
      researchLoading,
      commit,
      commitLoading
    } = this.props;

    if (!icon.repo && !isLoading && !commitLoading) {
      return (
        <div className="tools">
          <CSDevTeam />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }
    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            {researchLoading ? (
              <>
                <Loader />
                <div className="toolsHead__text"></div>
              </>
            ) : (
              <>
                <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
                <div className="toolsHead__text">
                  <div className="main">{icon.name}</div>
                  <div className="sub">
                    {researches.catagory}
                  </div>
                </div>
              </>
            )}
            <div className="toolsHead__title">development tool</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>

      

        <p className="dev__head">key developers</p>
        <div className="dev__cardCont mb--sm mb--sm--lastCard">
          {isLoading ? (
            <Loader />
          ) : (
            developers.map(developer => (
              <div className="tools__card dev__card" key={developer.id}>
               <a target="_blank" href={developer.html_url}>
               <img
                  src={developer.avatar_url}
                  alt="cardImg"
                  className="visual"
                />
                <div className="text">
                  <p className="name">{developer.name}</p>
                  <p className="role">{developer.login}</p>
                </div>
               </a>
              </div>
            ))
          )}
        </div>

        <div className="tools__card mb--sm">
          {commitLoading ? (
            <Loader />
          ) : (
            <CustomAreaChart
              data={commit}
              className="dev__chartCont"
              classChart="dev__chart"
            />
          )}
        </div>

        <div className="tools__slide">
          <ResCarousel className="tools__slider" data={carouselData} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  developers: state.developers.developers,
  isLoading: state.developers.isLoading,
  cryptos: state.navbarCryptos.cryptos,
  researches: state.researches.research,
  researchLoading: state.researches.isLoading,
  commitLoading: state.commit.isLoading,
  commit: state.commit.commit
});

const mapDispatchToProps = dispatch => ({
  getDevelopers: cryptoId => dispatch(getDevelopers(cryptoId)),
  getResearch: cryptoId => dispatch(getResearch(cryptoId)),
  getGithubCommit: cryptoId => dispatch(getGithubCommit(cryptoId))
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolDev)
);
