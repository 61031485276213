import React from "react";
// import { NavLink } from "react-router-dom";
import { Row, Col, UncontrolledCollapse } from "reactstrap";

const NavBar = ({ title }) => {
  return (
    <>
      <nav className="navBar">
        <Row>
          <Col xs="2" sm="1">
            <div className="navBar__toggler">
              <i className="fas fa-bars" id="toggler2"></i>
            </div>
          </Col>
          <Col xs="3" sm="3">
            <div className="navBar__logo">
              <img
                src={require("../../../assets/img/navBar/CFI-header-intel.png")}
                alt="cfiLogo"
              />
            </div>
          </Col>
          <Col xs="4" sm="5">
            <div className="navBar__heading">
              <p>{title}</p>
            </div>
          </Col>
          <Col xs="3" sm="3">
            <div className="navBar__logo2">
              <img
                src={require("../../../assets/img/navBar/KMC_logo_blk.png")}
                alt="cfiLogo"
              />
            </div>
          </Col>
        </Row>
      </nav>
      {/* <UncontrolledCollapse toggler="#toggler2">
        <div className="navBar__menus">
          <a
            href="https://medium.com/@cryptoflowintel/crypto-flow-intelligence-v0-01-1b404cca9b02"
            target="_blank"
            rel="noopener noreferrer"
            className="navBar__menu"
          >
            <div >intelligence</div>
          </a>

          <NavLink to="/media" className="navBar__menu">
            <div>media</div>
          </NavLink>

          <a
            href="https://medium.com/@cryptoflowintel/crypto-flow-intelligence-v0-01-1b404cca9b02"
            target="_blank"
            rel="noopener noreferrer"
            className="navBar__menu"
          >
            <div >data</div>
          </a>

          <NavLink to="/marketcap" className="navBar__menu">
            <div>market cap</div>
          </NavLink>

          <NavLink to="/explorer/chart" className="navBar__menu">
            <div>tools</div>
          </NavLink>
        </div>
      </UncontrolledCollapse> */}

      <UncontrolledCollapse toggler="#toggler2">
        <div className="navBar__menus">
          <a
            href="https://bitcoin-blog.keymajorcoin.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="navBar__menu"
          > 
            <div>INTELLIGENCE</div>
          </a>

          <a
            target="_blank"
            href="https://media.keymajorcoin.com"
            rel="noopener noreferrer"
            className="navBar__menu"
          >
            <div>MEDIA</div>
          </a>

          <a
            href="https://controlpanel.keymajorcoin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="navBar__menu"
          >
            <div>CONTROL PANEL</div>
          </a>

          <a
            target="_blank"
            href="https://marketcap.keymajorcoin.com"
            rel="noopener noreferrer"
            className="navBar__menu"
          >
            MARTKETCAP
          </a>
          {/* <a
            target="_blank"
            href="https://cfirecap.keymajorcoin.com/"
            rel="noopener noreferrer"
            className="navBar__menu"
          >
            WEEKLY RECAP
          </a> */}

          {/* <NavLink to="/marketcap" className="navBar__menu">
            <div>market cap</div>
          </NavLink>

          <NavLink to="/explorer/chart" className="navBar__menu">
            <div>tools</div>
          </NavLink> */}
        </div>
      </UncontrolledCollapse>
    </>
  );
};

export default NavBar;
