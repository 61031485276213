import React from "react";
import { CustomButton } from "../../Form/Form";
import { cardNews } from "../../../data/mediaPage/cardNews";
import MediaNewsCard from "./MediaNewsCard";
const MediaNews = () => {
  return (
    <>
      {/* <Row> */}
     
      {cardNews.map((news, index) => (
        // <Col xs="12" xl="6" >
        <MediaNewsCard
          key={index}
          img={news.img}
          title={news.title}
          date={news.date}
          author={news.author}
          coin={news.coin}
          news={news.news}
          className="outside"
        />
        // </Col>
      ))}
      {/* </Row> */}
      <div className="mediaHighlightNews__btnCont">
        <CustomButton btnText="Show More" className="mediaHighlightNews__btn" />
      </div>
    </>
  );
};

export default MediaNews;
