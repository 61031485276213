const initState = {
    isLoading: false,
    error: null,
    commit: []
  };
  
  const twitterReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_COMMIT_LOADING":
        return {
          ...state,
          error: null,
          isLoading: true
        };
      case "GET_COMMIT_SUCCESS":
        return {
          ...state,
          isLoading: false,
          commit: action.payload
        };
      case "GET_COMMIT_ERROR":
        return {
          ...state,
          commit: [],
          error: action.error,
          isLoading: false
        };
      default:
        return state;
    }
  };
  
  export default twitterReducer;
  