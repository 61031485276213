import { getMonth } from "../../utils/index";
import db from "../../config/firestore";
import axios from "axios";

let unsubscribeChart;

export const getGithubCommit = cryptoId => async dispatch => {
  dispatch({ type: "GET_COMMIT_LOADING" });
  try {
    const urls = await db
      .collection("CFN_icons")
      .where("symbol", "==", "CFIx" + cryptoId)
      .limit(1)
      .get();
    let url = {};
    urls.forEach(data => {
      url = { id: data.id, ...data.data() };
    });
    const repo = url.repo.split(".com/")[1];

    const { data } = await axios({
      method: "GET",
      url: `https://api.github.com/repos/${repo}/stats/commit_activity`,
      headers: {
        "User-Agent": "request",
        Authorization: `token 7cfdbb3ce3341f16256a643a0782fc70e9a062b3`
      }
    });

    const github = data.map(d => {
      const year = new Date(d.week * 1000).getFullYear();
      const month = getMonth(new Date(d.week * 1000).getMonth());
      const date = `${month} ${year}`;
      return {
        date, //new Date(d.week * 1000).toLocaleDateString(),
        total: d.total
      };
    });
    dispatch({ type: "GET_COMMIT_SUCCESS", payload: github });
  } catch (error) {
    dispatch({ type: "GET_COMMIT_ERROR", error });
  }
};

export const getCryptoChart = cryptoId => dispatch => {
  dispatch({ type: "GET_CRYPTO_CHART_LOADING" });
  unsubscribeChart && unsubscribeChart();
  unsubscribeChart = db
    .collection("CFIx_5min")
    .doc('CFIx'+cryptoId)
    .onSnapshot(
      snapshot => {
        const res = [];
        const obj = snapshot.data();
        const keys = Object.keys(obj).sort();
        keys.forEach(k => {
          res.push({
            date: new Date(k),
            volume: obj[k].vol,
            ...obj[k]
          });
        });
        dispatch({ type: "GET_CRYPTO_CHART_SUCCESS", payload: res });
      },
      error => dispatch({ type: "GET_CRYPTO_CHART_ERROR", error })
    );
};
