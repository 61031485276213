import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import ReactYoutube from "react-youtube";
import CSYoutube from "../../../ComingSoon/CSYoutube";

import {
  getYoutubes,
  loadMoreYoutube
} from "../../../../redux/actions/socialMediaAction";
// import { cardYoutube } from "../../../../data/mediaPage/cardYoutube";
import Loader from "../../../mini-components/Loader";

class AnalysisYoutube extends React.Component {
  componentDidUpdate(prevProps) {
    const { params } = this.props.match;
    if (params.cryptoId !== prevProps.match.params.cryptoId) {
      this.props.getYoutubes(params.cryptoId);
    }
  }
  componentDidMount() {
    const { params } = this.props.match;
    this.props.getYoutubes(params.cryptoId);
  }
  render() {
    const {
      youtubes,
      isLoading,
      nextPageToken,
      id,
      loadMoreYoutube,
      loadmoreLoading
    } = this.props;
    if (isLoading) {
      return <Loader />;
    }
    if (!isLoading && !youtubes.length) {
      return <CSYoutube />;
    }
    return (
      <div className="mediaHighlightSocial__card--youtubeCont">
        {youtubes.map(video => (
          <div
            className="mediaHighlightSocial__card--youtube mediaHighlightSocial__card--youtubeAnalysis"
            key={video.id}
          >
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="video embed-responsive-item"
                src={`https://www.youtube.com/embed/${video.contentDetails.videoId}`}
                allowFullScreen
                title="video"
              ></iframe>
            </div>
            <div className="text">
              <p className="main">{video.snippet.title}</p>
              <div className="creator">
                <i className="fab fa-youtube"></i> {video.snippet.channelTitle}
              </div>
              <div className="time">
                <i className="fas fa-clock"></i> {video.snippet.publishedAt}
              </div>
            </div>
          </div>
        ))}
        {loadmoreLoading ? (
          <Loader />
        ) : (
          <button
            className="btn btn--black analysisPage__btn"
            onClick={() => loadMoreYoutube(id, nextPageToken)}
          >
            Load More
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    youtubes: state.youtubes.youtubes,
    isLoading: state.youtubes.isLoading,
    nextPageToken: state.youtubes.nextPageToken,
    pageInfo: state.youtubes.pageInfo,
    id: state.youtubes.id,
    loadmoreLoading: state.youtubes.loadmoreLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getYoutubes: cryptoId => dispatch(getYoutubes(cryptoId)),
    loadMoreYoutube: (id, token) => dispatch(loadMoreYoutube(id, token))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisYoutube)
);
