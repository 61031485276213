import React from "react";
import ResCarousel from "../../../Carousel/ResCarousel";
import { getBChainStat } from "../../../../redux/actions/bChainStatAction";
import { getResearch } from "../../../../redux/actions/researchAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../mini-components/Loader";

import { notNAN } from "../../../../utils/index";

import CSGenesis from "../../../ComingSoon/CSGenesis";

class ToolGenesis extends React.Component {
  state = {
    icon: { id: null, name: null }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { cryptoId } = nextProps.match.params;
    const icon =
      nextProps.cryptos.length &&
      nextProps.cryptos.find(c => c.id === "CFIx" + cryptoId);
    if (icon.id !== prevState.icon.id) {
      return {
        icon
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { cryptoId } = this.props.match.params;
    const prevCryptoId = prevProps.match.params.cryptoId;

    if (cryptoId !== prevCryptoId) {
      this.props.getBChainStat(cryptoId);
      this.props.getResearch(cryptoId);
    }
  }

  componentDidMount() {
    const { cryptoId } = this.props.match.params;
    this.props.getResearch(cryptoId);
    this.props.getBChainStat(cryptoId);
  }
  render() {
    const { cryptoId } = this.props.match.params;
    const { icon } = this.state;
    const { researches, isLoading, bChain } = this.props;

    if (cryptoId === "CFIx") {
      return (
        <div className="tools">
          <CSGenesis />
          <div className="tools__slide">
            <ResCarousel className="tools__slider" />
          </div>
        </div>
      );
    }

    if (
      !isLoading &&
      !bChain.block_number &&
      !bChain.time_between_min &&
      !bChain.reward &&
      !bChain.difficulty &&
      !bChain.hash_rate &&
      !bChain.staked_tokens &&
      !bChain.staked_percentage &&
      !bChain.staked_yield &&
      !bChain.voters &&
      !bChain.tps
    ) {
      return  <div className="tools">
      <div className="tools__card mb--sm">
        <div className="toolsHead">
          <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
          <div className="toolsHead__text">
            <div className="main">{icon.name}</div>
            <div className="sub">
              {researches.catagory} 
            </div>
          </div>
          <div className="toolsHead__title">block genesis</div>
        </div>
      </div>
      <div className="tools__card mb--sm loader">
         <p>No Data.</p>
        </div>

        <div className="tools__slide">
          <ResCarousel className="tools__slider" />
        </div>
      </div>
    }

    return (
      <div className="tools">
        <div className="tools__card mb--sm">
          <div className="toolsHead">
            <img src={icon.iconURL} alt="coin" className="toolsHead__img" />
            <div className="toolsHead__text">
              <div className="main">{icon.name}</div>
              <div className="sub">
                {researches.catagory} 
              </div>
            </div>
            <div className="toolsHead__title">block genesis</div>
            {/* <i className="toolsHead__icon fas fa-compress"></i> */}
          </div>
        </div>

        {/* <div className="tools__card mb--sm">
        <p className="gen__chartHead">Pool DIstribution</p>
        <CustomBarChart className="gen__chartCont" classChart="gen__chart" />
      </div> */}

        <div className="tools__card mb--sm">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="gen__infos">
              {bChain.block_number && (
                <div className="gen__info">
                  <p className="title">Block number</p>
                  <p className="result">{bChain.block_number}</p>
                </div>
              )}
              {bChain.time_between_min && (
                <div className="gen__info">
                  <p className="title">Time between block</p>
                  <p className="result">{bChain.time_between_min}</p>
                </div>
              )}
              {bChain.reward && (
                <div className="gen__info">
                  <p className="title">Mining reward</p>
                  <p className="result">{bChain.reward}</p>
                </div>
              )}

              {bChain.difficulty && (
                <div className="gen__info">
                  <p className="title">Mining difficulty</p>
                  <p className="result">{bChain.difficulty}</p>
                </div>
              )}
              {bChain.hash_rate && (
                <div className="gen__info">
                  <p className="title">Mining hashrate</p>
                  <p className="result">{bChain.hash_rate}</p>
                </div>
              )}
              {bChain.staked_tokens && (
                <div className="gen__info">
                  <p className="title">Staked assets</p>
                  <p className="result">{bChain.staked_tokens}</p>
                </div>
              )}

              {bChain.staked_percentage && (
                <div className="gen__info">
                  <p className="title">Staked percentage</p>
                  <p className="result">{bChain.staked_percentage}</p>
                </div>
              )}

              {bChain.staked_yield && (
                <div className="gen__info">
                  <p className="title">Staked Yield</p>
                  <p className="result">{bChain.staked_yield}</p>
                </div>
              )}

              {bChain.voters && (
                <div className="gen__info">
                  <p className="title">Number of voters</p>
                  <p className="result">{bChain.voters}</p>
                </div>
              )}

              {bChain.tps && (
                <div className="gen__info">
                  <p className="title">Transactions per second</p>
                  <p className="result">{bChain.tps}</p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="tools__slide">
          <ResCarousel className="tools__slider" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.bChain.isLoading,
  bChain: state.bChain.bChain,
  researches: state.researches.research,
  researchLoading: state.researches.isLoading,
  cryptos: state.navbarCryptos.cryptos
});

const mapDispatchToProps = dispatch => ({
  getResearch: cryptoId => dispatch(getResearch(cryptoId)),
  getBChainStat: cryptoId => dispatch(getBChainStat(cryptoId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToolGenesis)
);
