import React from "react";

export default function MarketCapData({
  id,
  image,
  coin,
  price,
  color,
  change,
  mfx,
  stripped
}) {
  return (
    <tr className={stripped ? "stripped" : null}>
      <td className="numb">{id}</td>
      <td>
        <div className="asset">
          <img src={image} alt="coinImg" className="asset__image" />
          <p>{coin}</p>
        </div>
      </td>
      <td>$ {price}</td>
      <td>
        <p className={`price price--${color === "red" ? "red" : "green"}`}>
          {color === "red" ? <i className="fas fa-minus"></i> : null}
          {change}%
        </p>
      </td>
      <td>$ {mfx}</td>
    </tr>
  );
}
