import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from "reactstrap";
import { CustomInput, CustomButton } from "../Form/Form";
import * as ROUTES from "../../constants/routes";

class SignIn extends Component {
  render() {
    return (
      <div className="signIn">
        <div className="card signIn__card">
          <img
            src={require("../../assets/img/signIn/bitcoin1.svg")}
            alt="signInImg"
            className="signIn__illust1"
          />
          <img
            src={require("../../assets/img/signIn/bitcoin2.svg")}
            alt="signInImg"
            className="signIn__illust2"
          />
          <p className="signIn__heading fw--medium text--orange al--center">
            Sign In
          </p>
          <Form className="signIn__form">
            <CustomInput
              label="none"
              type="email"
              name="email"
              id="email"
              placeholder="Email Here"
              icon="fas fa-envelope"
            />
            <CustomInput
              label="none"
              type="password"
              name="password"
              id="password"
              placeholder="Password Here"
              icon="fas fa-lock"
            />
            <div className="signIn__info">
              <Row>
                <Col xs="6">
                  <p className="signIn__forgot fw--medium">Forgot Password ?</p>
                </Col>
                <Col xs="6 al--right">
                  <Link to={ROUTES.MEDIA}>
                    <CustomButton
                      btnText="Sign In"
                      className="signIn__btn"
                      btnColor="orange"
                      btnIcon="none"
                    />
                  </Link>
                </Col>
                <Col xs="12 al--center">
                  <p className="signIn__register fw--medium">
                    Doesn't Have An Account ? Sign Up{" "}
                    <Link to={ROUTES.SIGNUP}>
                      <span>Here</span>
                    </Link>
                  </p>
                  <p className="signIn__or fw--medium">- or Sign Up with -</p>
                  <CustomButton
                    btnText="google"
                    className="signIn__btnsocial"
                    btnIcon="fab fa-google"
                    btnColor="red"
                  />
                  <CustomButton
                    btnText="facebook"
                    className="signIn__btnsocial"
                    btnIcon="fab fa-facebook-f"
                    btnColor="blue"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default SignIn;
