const initState = {
  isLoading: false,
  error: null,
  cryptos: [],
  icons: [],
};

const navbarCrypto = (state = initState, action) => {
  switch (action.type) {
    case "GET_NAVBAR_CRYPTOS_LOADING":
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case "GET_NAVBAR_CRYPTOS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        icons: action.payload,
        cryptos: action.payload.filter(each => each.id !== undefined)
      };
    case "GET_NAVBAR_CRYPTOS_ERROR":
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};

export default navbarCrypto;
