const initState = {
    isLoading: false,
    error: null,
    research: {},
  };
  
  const researchReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_RESEARCH_LOADING":
        return {
          ...state,
          error: null,
          isLoading: true
        };
      case "GET_RESEARCH_SUCCESS":
        return {
          ...state,
          isLoading: false,
          research: action.payload
        };
      case "GET_RESEARCH_ERROR":
        return {
          ...state,
          research: {},
          error: action.error,
          isLoading: false
        };
      default:
        return state;
    }
  };
  
  export default researchReducer;
  