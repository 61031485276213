import db from "../../config/firestore";

export const getNavbarCryptos = () => dispatch => {
  dispatch({ type: "GET_NAVBAR_CRYPTOS_LOADING" });
  db.collection("CFIx_5min")
    .limit(17)
    .onSnapshot(
      snapshots => {
        db.collection("CFN_icons")
          .get()
          .then(result => {
            const tempObject = {};
            snapshots.forEach(snapshot => {
              tempObject[snapshot.id] = {
                id: snapshot.id,
                ...snapshot.data()[Object.keys(snapshot.data()).reverse()[0]]
              };
            });
            result.forEach(res => {
              tempObject[res.data().symbol] = {
                ...tempObject[res.data().symbol],
                ...res.data()
              };
            });

            // const cryptos = Object.entries(tempObject).map(obj => {
            //   const data = obj[1];
            //   if (data.id === "CFIxQTUM") data.order = 1;
            //   if (data.id === "CFIxETC") data.order = 2;
            //   if (data.id === "CFIxNEO") data.order = 3;
            //   if (data.id === "CFIxIOTA") data.order = 4;
            //   if (data.id === "CFIxADA") data.order = 5;
            //   if (data.id === "CFIxTRX") data.order = 6;
            //   if (data.id === "CFIxEOS") data.order = 7;
            //   if (data.id === "CFIxETH") data.order = 8;
  
            //   if (data.id === "CFIxBTC") data.order = 10;
            //   if (data.id === "CFIxXRP") data.order = 11;
            //   if (data.id === "CFIxBCH") data.order = 12;
            //   if (data.id === "CFIxLTC") data.order = 13;
            //   if (data.id === "CFIxXLM") data.order = 14;
            //   if (data.id === "CFIxXMR") data.order = 15;
            //   if (data.id === "CFIxDASH") data.order = 16;
            //   if (data.id === "CFIxZEC") data.order = 17;
            //   return { ...data };
            // });
            const cryptos = Object.entries(tempObject).map(obj => obj[1])//.filter(d => d.id !== "CFIxCFIx")
            cryptos.sort((a, b) => a.order - b.order )
            dispatch({ type: "GET_NAVBAR_CRYPTOS_SUCCESS", payload: cryptos });
          });
      },
      error => dispatch({ type: "GET_NAVBAR_CRYPTOS_ERROR", error })
    );
};
